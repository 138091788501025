import {Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material'
import {PageLayout} from '@goschool/components'
import {Trans, useTranslation} from 'react-i18next'
import {useMemo} from 'react'

import {GoSchool} from '@goschool/routing'
import type {QueryDocumentSnapshot} from 'firebase/firestore'
import type {CourseChat} from '@goschool/model'
import {useAllChats, useChatCourse, useChatCreatedBy, useChatOrganization} from '@goschool/model'
import {useFirestore} from '@goschool/react-firebase'
import {TransRelTime} from '@goschool/i18n'
import {Link as RouterLink} from 'react-router-dom'


function allChatsBreadcrumbs() {
  return [
    {title: 'Admin', href: GoSchool.admin},
    {
      title: 'Chats',
      href: '/admin/chats'
    }
  ]
}

export function AllChatsPage() {
  return <PageLayout
    fullScreen={false}
    breadcrumbs={allChatsBreadcrumbs()}>
    <Typography variant="h4" component="h1" gutterBottom={true}><Trans i18nKey="course:chats.title"/></Typography>
    <AllChatsTable/>
  </PageLayout>
}


function AllChatsTable() {
  const chats = useAllChats()
  const {t} = useTranslation()

  return <TableContainer component={Paper} sx={{overflow: 'hidden', maxWidth: '100%'}}>
    <Table size="small" sx={{maxWidth: '100%', tableLayout: 'auto'}}>
      <TableHead>
        <TableRow>
          <TableCell>{t('course:chats.tableHeaders.course')}</TableCell>
          <TableCell>{t('course:chats.tableHeaders.title')}</TableCell>
          <TableCell>{t('course:chats.tableHeaders.user')}</TableCell>
          <TableCell>{t('course:chats.tableHeaders.lastMessage')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(chats ?? []).map((chat) => <ChatRow key={chat.id} snapshot={chat}/>)}
      </TableBody>
    </Table>
  </TableContainer>
}


function ChatRow({snapshot}: { snapshot: QueryDocumentSnapshot<CourseChat> }) {
  const chat = useMemo(() => snapshot.data(), [snapshot])
  const firestore = useFirestore()
  const createdBySnapshot = useChatCreatedBy(chat)
  const orgSnapshot = useChatOrganization(snapshot)
  const courseSnapshot = useChatCourse(snapshot)

  const org = useMemo(
    () => orgSnapshot?.data(),
    [orgSnapshot]
  )

  const course = useMemo(
    () => courseSnapshot?.data(),
    [courseSnapshot]
  )
  const createdBy = useMemo(
    () => createdBySnapshot?.data(),
    [createdBySnapshot]
  )

  return <TableRow>
    <TableCell title={org?.name}>{course?.title}</TableCell>
    <TableCell sx={{whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '400px', textOverflow: 'ellipsis'}}>
      <Link to={GoSchool.courseChatView(snapshot.ref)} component={RouterLink}>
        {chat.title ?? 'New Chat'}
      </Link>
    </TableCell>
    <TableCell>{createdBy?.name}</TableCell>
    <TableCell><TransRelTime ts={chat.updated_at}/> </TableCell>
  </TableRow>
}
