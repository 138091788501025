import type {DocumentReference, QueryDocumentSnapshot} from 'firebase/firestore'
import type {CourseDocument, CourseDocumentState} from '@goschool/model'
import {useGoSchoolFunctions} from '@goschool/model'
import type {SvgIconProps} from '@mui/material'
import {
  Box,
  Divider,
  IconButton,
  LinearProgress,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Typography
} from '@mui/material'
import {Trans, useTranslation} from 'react-i18next'
import {useCallback, useMemo, useState} from 'react'
import {GoSchool} from '@goschool/routing'
import LibraryMusicIcon from '@mui/icons-material/LibraryMusic'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import QuizIcon from '@mui/icons-material/Quiz'
import DeleteIcon from '@mui/icons-material/Delete'
import {useUserContext} from '@goschool/auth'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'

export function CourseDocumenList({courseDocuments}: { courseDocuments: QueryDocumentSnapshot<CourseDocument>[]; }) {
  return <Paper>
    <List>
      {courseDocuments.map((documentSnapshot, i) =>
        <CourseDocumenItem
          key={documentSnapshot.id}
          documentSnapshot={documentSnapshot}
          document={documentSnapshot.data()}
          isLast={i === courseDocuments.length - 1}
        />
      )}
    </List>
  </Paper>
}

function CourseDocumenItem({
  documentSnapshot,
  document,
  isLast
}: {
  documentSnapshot: QueryDocumentSnapshot<CourseDocument>;
  document: CourseDocument;
  isLast: boolean;
}) {
  const authors = useMemo(() => {
    if (document.authors == null || document.authors.length === 0) {
      return null
    } else {
      return document.authors?.join(', ')
    }
  }, [document])
  const state = useDocumentState(document)
  return <>
    <ListItem alignItems="flex-start" secondaryAction={<CourseDocumentActions documentSnaphot={documentSnapshot}/>}>
      <ListItemIcon>
        <CourseDocumentIcon document={document} color="info"/>
      </ListItemIcon>
      <ListItemText
        slotProps={{
          primary: {variant: 'body1'},
          secondary: {variant: 'caption'}
        }}
        primary={
          <>
            <Link href={GoSchool.document(documentSnapshot.ref)}>
              {document.title}
            </Link>
            {authors != null ? <Typography variant="caption"> {authors}</Typography> : null}
          </>
        }
        secondary={
          state.status === 'succeeded' ? document.lead : <DocumentState state={state}/>
        }
      />
    </ListItem>
    {!isLast && <Divider variant="middle" component="li"/>}
  </>
}

function CourseDocumentActions({documentSnaphot}: { documentSnaphot: QueryDocumentSnapshot<CourseDocument>; }) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  return <>
    <IconButton onClick={handleClick}><MoreHorizIcon/></IconButton>
    <Menu open={anchorEl != null} onClose={handleClose} anchorEl={anchorEl}
      transformOrigin={{horizontal: 'right', vertical: 'top'}}
      anchorOrigin={{horizontal: 'right', vertical: 'bottom'}}>
      <DeleteDocumentMenuItem documentRef={documentSnaphot.ref} handleClose={handleClose}/>
    </Menu>
  </>
}

function DeleteDocumentMenuItem({
  documentRef, handleClose
}: {
  documentRef: DocumentReference<CourseDocument>, handleClose: () => void
}) {
  const {roles} = useUserContext()

  const {deleteDocument} = useGoSchoolFunctions()
  const {t} = useTranslation()

  const handleDelete = useCallback(async () => {
    try {
      if (window.confirm(t('document:actions.confirm-delete'))) {
        await deleteDocument(documentRef)
        handleClose()
      }
    } catch (error) {
      console.error(error)
    }
  }, [deleteDocument, documentRef, handleClose, t])

  if (roles.includes('admin') || roles.includes('instructor')) {
    return <MenuItem onClick={handleDelete}><DeleteIcon
      fontSize="small"/><ListItemText><Trans i18nKey="document:actions.delete"/></ListItemText></MenuItem>
  }
}

function CourseDocumentIcon({document, ...iconProps}: { document: CourseDocument } & Omit<SvgIconProps, 'fontSize'>) {
  if (document.content_type === 'application/pdf') {
    return <PictureAsPdfIcon fontSize="large" {...iconProps}/>
  } else if (
    document.content_type?.startsWith('audio/') ||
    document.content_type?.startsWith('video/')
  ) {
    return <LibraryMusicIcon fontSize="large" {...iconProps}/>
  } else {
    return <QuizIcon fontSize="large" {...iconProps}/>
  }
}


export function DocumentState({state}: { state: StateProps }) {
  switch (state.transition) {
  case 'pending':
    return <State {...state} steps={4}/>
  case 'preprocess_upload':
    return <State {...state} steps={4}/>
  case 'extract_content':
    return <State {...state} steps={4}/>
  case 'transcribe_audio':
    return <State {...state} steps={4}/>
  case 'content_analysis':
    return <State {...state} steps={4}/>
  default:
    if (state.status === 'succeeded') {
      return null
    }
    return <State {...state} steps={4}/>
  }
}

interface StateProps {
  transition:
    | 'pending'
    | 'preprocess_upload'
    | 'extract_content'
    | 'transcribe_audio'
    | 'content_analysis'
    | 'index_content';
  status: 'processing' | 'succeeded' | 'failed';
  progress: number | undefined;
  total: number | undefined;
  step: number;
}

export function useDocumentState(document: CourseDocument): StateProps {
  return useMemo(() => {
    const state: CourseDocumentState = document.state ?? {}
    const transitions: (keyof CourseDocumentState)[] = [
      'preprocess_upload',
      'extract_content',
      'transcribe_audio',
      'content_analysis',
      'index_content'
    ]

    let step = 0
    for (const key of transitions) {
      const keyState = state[key]
      if (keyState?.status === 'succeeded') {
        step++
      }
      if (
        keyState != null &&
        (keyState.status !== 'succeeded' || key === 'index_content')
      ) {
        const {progress, status, total} = keyState

        return {transition: key, status, progress, total, step}
      }
    }

    return {
      transition: 'pending',
      status: 'processing',
      step,
      progress: undefined,
      total: undefined
    }
  }, [document])
}

function State(props: StateProps & { steps: number }) {
  const {transition, progress, total, status, step, steps} = props
  const {t} = useTranslation()
  const value = useMemo(() => {
    return (100 * step + (progress ?? status === 'succeeded' ? 100 : 0)) / steps
  }, [progress, status, step, steps])

  return <Stack direction="row" alignItems="center" justifyContent="flex-start">
    <Box width={250} mr={1}>
      <LinearProgress
        color={status === 'failed' ? 'error' : 'info'}
        variant="determinate"
        value={value}
      />
    </Box>
    <Typography variant="caption">
      {t(`document:status.${transition}`)}
      {value != null && total != null ? ` ${progress}/${total}` : ''}
    </Typography>
  </Stack>
}
