import type { DocumentReference, CollectionReference } from '@firebase/firestore'
import { addDoc, deleteField, serverTimestamp, updateDoc } from '@firebase/firestore'
import type { StorageError, UploadTaskSnapshot, FirebaseStorage } from '@firebase/storage'
import { ref, uploadBytesResumable } from '@firebase/storage'
import type { Dispatch } from 'react'
import type { FileUpload } from '@goschool/model'


export async function uploadBatch(
  files: FileList,
  uploadsCollection: CollectionReference<FileUpload>,
  uploadsDirectory: string,
  dispatch: Dispatch<UploadAction>, storage: FirebaseStorage) {
  dispatch({ type: 'batch' })

  return Promise.all(
    Array.from(files).map(
      (file, index) =>
        uploadFile(file, index, uploadsCollection, uploadsDirectory, dispatch, storage)
    )
  )
}

async function uploadFile(
  file: File, index: number,
  uploadsCollection: CollectionReference<FileUpload>,
  uploadsDirectory: string,
  dispatch: Dispatch<UploadAction>, storage: FirebaseStorage
): Promise<[DocumentReference<FileUpload>, File] | null> {
  try {
    const uploadRef = await addDoc(
      uploadsCollection,
      {
        status: 'pending',
        created_at: serverTimestamp(),
        file_name: file.name,
        size: file.size,
        storage_path: null
      }
    )

    const uploadId = uploadRef.id
    const storageRef = ref(
      storage, `${uploadsDirectory}/${uploadId}-${file.name}`
    )
    dispatch({ index, type: 'upload', uploadRef })

    await updateDoc(uploadRef, {
      status: 'uploading',
      storage_path: storageRef.fullPath
    })
    const uploadTask = uploadBytesResumable(storageRef, file)

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = snapshot.bytesTransferred / snapshot.totalBytes * 100
        dispatch({ index, type: 'progress', progress })
      },
      (error) => {
        dispatch({ index, type: 'error', error })
      },
      async () => {
        await updateDoc(uploadRef, { status: 'completed', progress: deleteField() })
        dispatch({ index, type: 'completed' })
      }
    )

    const uploadSnaphot: UploadTaskSnapshot = await uploadTask
    if (uploadSnaphot.state==='success') {
      return [uploadRef, file]
    }
    return null
  } catch (error) {
    dispatch({ index, type: 'error', error })
    return null
  }
}

/*
 * Reducer states
 */

/**
 * The upload document is being created in firestore
 */
export interface Creating {
  state: 'creating';
}

/**
 * The upload is in progress
 */
export interface Uploading {
  state: 'uploading' | 'paused';
  uploadRef: DocumentReference<FileUpload>;
  progress: number;
}

/**
 * The upload has succeeded
 */
export interface UploadSucceeded {
  state: 'succeeded';
  uploadRef: DocumentReference<FileUpload>;
  progress: number;
}

/**
 * The upload has failed
 */
export interface UploadFailed {
  state: 'failed';
  uploadRef: DocumentReference<FileUpload>;
  progress: number;
  error: StorageError | unknown;
}

/**
 * File upload state
 */
export type UploadState = Creating | Uploading | UploadSucceeded | UploadFailed;


/**
 * Batch upload state
 */
export type BatchState = {
  files?: FileList
  uploads?: UploadState[]
}


/*
 * Actions for the reducer
 */

interface SelectFiles {
  type: 'select';
  files: FileList;
}

interface StartBatch {
  type: 'batch';
}

interface Reset {
  type: 'reset';
}

interface StartUpload {
  index: number;
  type: 'upload';
  uploadRef: DocumentReference<FileUpload>;
}

interface UploadProgress {
  index: number;
  type: 'progress';
  progress: number;
}

interface UploadError {
  index: number;
  type: 'error';
  error: StorageError | unknown;
}

interface UploadCompleted {
  index: number;
  type: 'completed';
}

type UploadAction = SelectFiles | StartBatch | StartUpload | UploadProgress | UploadError | UploadCompleted | Reset


/**
 * Reducer for the file upload state
 * @param batchState
 * @param action
 */
export function uploadReducer(batchState: BatchState, action: UploadAction): BatchState {
  if (action.type==='select') {
    if (batchState.uploads!==undefined) {
      throw new Error('Cannot start upload while another batch is in progress')
    }

    return { files: action.files }
  }

  if (action.type==='batch') {
    if (batchState.files===undefined) {
      throw new Error('No files selected')
    }

    return {
      ...batchState,
      uploads: Array.from(batchState.files).map(() => ({ state: 'creating' }))
    }
  }

  if (action.type==='reset') {
    return {}
  }

  const index = action.index
  if (batchState.uploads===undefined) {
    throw new Error('No upload in progress')
  }

  const state = batchState.uploads[index]
  if (state===undefined) {
    throw new Error('Invalid upload index')
  }

  switch (action.type) {
  case 'upload':
    if (state?.state!=='creating') {
      throw new Error(`Invalid action ${action.type} in state ${state?.state}`)
    }
    return updateFileState(
      batchState, index, { state: 'uploading', uploadRef: action.uploadRef, progress: 0 }
    )
  case 'progress':
    if (state?.state!=='uploading') {
      throw new Error(`Invalid action ${action.type} in state ${state?.state}`)
    }
    return updateFileState(
      batchState, index, { progress: action.progress }
    )

  case 'completed':
    if (state?.state!=='uploading') {
      throw new Error(`Invalid action ${action.type} in state ${state?.state}`)
    }
    return updateFileState(
      batchState, index, { state: 'succeeded' }
    )

  case 'error':
    if (!['uploading', 'failed'].includes(state?.state)) {
      throw new Error(`Invalid action ${action.type} in state ${state?.state}`)
    }
    return updateFileState(
      batchState, index, { state: 'failed', error: action.error }
    )
  }
}

/**
 * Update the state of the indexth file in the batch
 * @param batchState
 * @param index
 * @param update
 */
function updateFileState(batchState: BatchState, index: number, update: Partial<UploadState>): BatchState {
  const uploads = batchState.uploads
  if (uploads==null) {
    throw new Error('No uploads in progress')
  }

  return {
    ...batchState,
    uploads: uploads.map(
      (s, i) => i===index ? { ...s, ...update } as UploadState:s
    )
  }
}
