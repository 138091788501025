import {Box, Card, CardActionArea, CardContent, Chip, Stack, Typography} from '@mui/material'
import {shuffle} from 'lodash'
import {useCallback, useMemo} from 'react'
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import {useChatContext} from './ChatContext'
import type {ConversationStarter} from '@goschool/model'
import {isAdvancedConversationStarter} from '@goschool/model'

export function ConversationStarters() {
  const {conversationStarters} = useChatContext()

  const selected = useMemo(
    () => shuffle(conversationStarters ?? []).slice(0, 4),
    [conversationStarters]
  )

  if (conversationStarters == null) {
    return null
  }

  return (
    <Box px={4}>
      <Stack direction="row" justifyContent="center" spacing={2}>
        {selected.map((starter, i) =>
          <ConversationStarterChip key={i} starter={starter}/>
        )}
      </Stack>
    </Box>
  )
}


function ConversationStarterChip({starter}: { starter: ConversationStarter }) {
  const {chatManager} = useChatContext()

  const sendMessage = useCallback(
    (message: string) => () => chatManager.post(message),
    [chatManager]
  )
  const [title, message] = useMemo(
    () => isAdvancedConversationStarter(starter)
      ? [starter.title, starter.message]
      : [starter, starter],
    [starter]
  )

  return <Chip
    variant="outlined" icon={<TipsAndUpdatesIcon fontSize="small" color="info"/>} label={title}
    onClick={sendMessage(message)}/>
}

function ConversationStarterCard({starter}: { starter: ConversationStarter }) {
  const {chatManager} = useChatContext()

  const sendMessage = useCallback(
    (message: string) => () => chatManager.post(message),
    [chatManager]
  )
  const [title, message] = useMemo(
    () => typeof starter === 'string'
      ? [starter, starter]
      : [starter.title, starter.message],
    [starter]
  )

  return <Card>
    <CardActionArea onClick={sendMessage(message)}>
      <CardContent sx={{height: '6rem'}}>
        <Typography variant="body2">{title}</Typography>
      </CardContent>
    </CardActionArea>
  </Card>
}
