import {useChatContext} from './ChatContext'

import {Box, styled} from '@mui/material'
import {ChatMessageView} from './ChatMessageView'
import {ChatViewContextProvider} from './ChatViewContext'
import {PromptForm} from './PromptForm'
import {useFirebaseAuth} from '@goschool/react-firebase'
import {MessageNodeContextProvider} from './MessageNodeContext'
import {ConversationStarters} from './ConversationStarters'


export function ChatMessages() {
  const {chatManager} = useChatContext()

  return <MessagesContainer>
    <ChatViewContextProvider>
      <Messages key={chatManager.id ?? 'new'}/>
    </ChatViewContextProvider>
  </MessagesContainer>
}

export function InitialPrompt() {
  return <InitialPromptContainer>
    <ChatViewContextProvider>
      <PromptFormContainer>
        <PromptForm/>
      </PromptFormContainer>
      <ConversationStarters/>
    </ChatViewContextProvider>
  </InitialPromptContainer>
}

export function ChatPrompt() {
  const {mode, chatManager} = useChatContext()
  const {user} = useFirebaseAuth()
  const {chat} = chatManager

  const otherUser = chat != null && (user == null || chat.created_by !== user.uid)

  const readonly = mode === 'view' || otherUser
  if (readonly) {
    return null
  }

  return (
    <PromptFormContainer>
      <PromptForm/>
    </PromptFormContainer>
  )
}

export function Messages() {
  const chatContext = useChatContext()

  const {chatManager, thread} = chatContext

  if (thread == null) {
    return null
  }
  return <>
    {thread.map((node, i) => {
      const parent = i > 0 ? thread[i - 1] : {children: [node]}
      return <MessageNodeContextProvider key={node?.message.id ?? chatManager.id} node={node} parent={parent}>
        <ChatMessageView/>
      </MessageNodeContextProvider>
    })}
  </>
}

export const InitialPromptContainer = styled(Box, {
  name: 'InitialPromptContainer',
  slot: 'Root'
})(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(4), // Space between messages,
  flexGrow: 1,
  justifyContent: 'center'
}))

export const MessagesContainer = styled(Box, {
  name: 'MessagesContainer',
  slot: 'Root'
})(({theme}) => ({
  overflowY: 'auto', // Become scrollable vertically
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2), // Space between messages,
  flexGrow: 1
}))

const PromptFormContainer = styled(Box, {name: 'UserMessage', slot: 'Root'})(
  ({theme}) => ({
    flexShrink: 0,
    flexGrow: 0,
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[1]
  })
)
