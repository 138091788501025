import type {AgentMessage, AgentMessageFeedback} from '@goschool/model'
import {isAgentMessage, useGoSchoolFunctions} from '@goschool/model'
import {useFirebaseAuth} from '@goschool/react-firebase'
import CachedOutlinedIcon from '@mui/icons-material/CachedOutlined'
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import ThumbDownOutlinedIcon from '@mui/icons-material/ThumbDownOutlined'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import ThumbUpOutlinedIcon from '@mui/icons-material/ThumbUpOutlined'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import type {BoxProps, IconButtonProps} from '@mui/material'
import {Alert, AlertTitle, Box, Button, Chip, IconButton, Stack, styled, TextField} from '@mui/material'
import {Timestamp} from 'firebase/firestore'
import React, {useCallback, useLayoutEffect, useMemo, useState} from 'react'
import {useChatContext} from './ChatContext'
import {useMessageNodeContext} from './MessageNodeContext'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import PendingIcon from '@mui/icons-material/Pending'
import {Trans, useTranslation} from 'react-i18next'
import CloseIcon from '@mui/icons-material/Close'

export function AgentMessageActions({message}: { message: AgentMessage }) {
  const {parent, node} = useMessageNodeContext()
  const {chatManager} = useChatContext()
  const feedbackActions = useAgentMessageFeedback()

  const [prevSibling, nextSibling] = useMemo(
    () => {
      const nodeIndex = parent.children.indexOf(node)
      return [
        nodeIndex > 0 ? parent.children[nodeIndex - 1] : null,
        nodeIndex < parent.children.length - 1 ? parent.children[nodeIndex + 1] : null
      ]
    },
    [parent, node]
  )

  return <MessageActions>
    <CopyContentButton message={message}/>
    <LikeButton {...feedbackActions} />
    <DislikeButton {...feedbackActions} />
    <RetryResponseButton/>
    {parent.children.length > 1 &&
        <MessageActionButton
          disabled={prevSibling == null}
          onClick={prevSibling != null ?
            () => chatManager.selectThread(prevSibling) :
            undefined}>
          <NavigateBeforeIcon/>
        </MessageActionButton>}
    {parent.children.length > 1 && <MessageActionButton
      disabled={nextSibling == null}
      onClick={nextSibling != null
        ? () => chatManager.selectThread(nextSibling)
        : undefined}>
      <NavigateNextIcon/>
    </MessageActionButton>}
    <FeedbackCommentBox {...feedbackActions} />
  </MessageActions>
}


function LikeButton({feedback, submitFeedback, isSubmitting}: FeedbackActions) {
  const {submitState} = useChatContext()

  const {t} = useTranslation()

  if (feedback?.liked) {
    return <MessageActionButton
      title={t('chat:message.actions.like')}
      onClick={() => submitFeedback(null, null)}
      disabled={submitState !== 'idle' || isSubmitting}>
      <ThumbUpIcon color="success"/>
    </MessageActionButton>
  } else {
    return <MessageActionButton
      title={t('chat:message.actions.like')}
      onClick={() => submitFeedback(true, null)}
      disabled={submitState !== 'idle' || isSubmitting}>
      <ThumbUpOutlinedIcon/>
    </MessageActionButton>
  }
}

function DislikeButton({feedback, submitFeedback, isSubmitting, requireComment}: FeedbackActions) {
  const {submitState} = useChatContext()
  const {t} = useTranslation()

  if (feedback?.liked === false) {
    return <MessageActionButton
      title={t('chat:message.actions.dislike')}
      onClick={() => submitFeedback(null, null)}
      disabled={submitState !== 'idle' || isSubmitting}>
      <ThumbDownIcon color="error"/>
    </MessageActionButton>
  } else {
    return <MessageActionButton
      title={t('chat:message.actions.dislike')}
      onClick={() => submitFeedback(false, null)}
      disabled={submitState !== 'idle' || isSubmitting}>
      <ThumbDownOutlinedIcon/>
    </MessageActionButton>
  }
}

function FeedbackCommentBox({submitFeedback, isSubmitting, requireComment, noComment}: FeedbackActions) {
  const {t} = useTranslation()
  const predefinedComments: string[] = t('chat:message.actions.predefined-dislike-comments', {returnObjects: true}) as string[]
  const [comment, setComment] = useState('')

  if (requireComment) {
    return <Alert
      variant="outlined"
      severity="info"
      action={
        <IconButton onClick={noComment}><CloseIcon/></IconButton>
      }>
      <Stack direction="column" gap={2}>
        <AlertTitle><Trans i18nKey="chat:message.actions.tell-us-more"/></AlertTitle>
        <Stack direction="row" gap={1} flexWrap="wrap">{predefinedComments.map((c, i) =>
          <Chip variant="outlined" color="info" size="small" onClick={() => submitFeedback(false, c)} key={i}
            label={c}/>)}
        </Stack>
        <TextField multiline fullWidth size="small" value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={t('chat:message.actions.dislike-comment-placeholder')}/>
        <Stack direction="row" justifyContent="flex-end" gap={1}>
          <Button variant="contained" size="small" disabled={isSubmitting}
            onClick={() => submitFeedback(false, comment)}>{t('chat:message.actions.submit-comment')}</Button>
        </Stack>
      </Stack>
    </Alert>
  } else {
    return null
  }
}

function CopyContentButton({message}: { message: AgentMessage }) {
  const [justCopied, setJustCopied] = React.useState(false)
  const {submitState} = useChatContext()
  const copyContent = useCallback(
    () => {
      navigator.clipboard.writeText(message.content)
      setJustCopied(true)
    },
    [message]
  )

  useLayoutEffect(() => {
    if (justCopied) {
      const timeout = setTimeout(() => setJustCopied(false), 1000)
      return () => clearTimeout(timeout)
    }
  },
  [justCopied]
  )

  const {t} = useTranslation()

  return <MessageActionButton
    title={t('chat:message.actions.copy-content')}
    disabled={submitState !== 'idle' && !justCopied} edge="start" onClick={copyContent}>
    {justCopied ? <CheckCircleIcon color="success"/> : <ContentCopyOutlinedIcon/>}
  </MessageActionButton>
}

function RetryResponseButton() {
  const {node} = useMessageNodeContext()
  const {submitState, mode} = useChatContext()
  const {retryResponse} = useGoSchoolFunctions()

  const retry = useCallback(() => {
    retryResponse(node.message.ref)
  }, [node.message.ref, retryResponse])
  const {t} = useTranslation()

  if (mode === 'view') {
    return null
  }

  return <MessageActionButton
    title={t('chat:message.actions.retry')}
    disabled={submitState !== 'idle'} onClick={retry}>
    {submitState !== 'idle' ? <PendingIcon/> : <CachedOutlinedIcon/>}
  </MessageActionButton>
}

function MessageActions({children, ...props}: Omit<BoxProps, 'className'>) {
  return <StyledMessageActions className="MessageActions" {...props}>{children}</StyledMessageActions>
}

const StyledMessageActions = styled(Box, {
  name: 'MessageActions',
  slot: 'Root'
})(({theme}) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'stretch'
}))

function MessageActionButton({children, ...props}: Omit<IconButtonProps, 'size' | 'className'>) {
  return <StyledMessageActionButton
    size="small"
    className="MessageActionButton" {...props}>{children}</StyledMessageActionButton>
}

const StyledMessageActionButton = styled(
  IconButton,
  {name: 'StyledMessageActionButton'})(({theme}) => ({
  '& .MuiSvgIcon-root': {
    width: '0.8rem',
    height: '0.8rem'
  }
}))


export interface FeedbackActions {
  feedback: AgentMessageFeedback | undefined;
  submitFeedback: (liked: (boolean | null), comment: (string | null)) => void;
  isSubmitting: boolean
  requireComment: boolean
  noComment: () => void
}

export function useAgentMessageFeedback() {
  const {user} = useFirebaseAuth()
  const {message, node} = useMessageNodeContext()
  const {saveFeedback} = useGoSchoolFunctions()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [feedback, setFeedback] = useState<AgentMessageFeedback | undefined>()
  const [requireComment, setRequireComment] = useState(false)
  useLayoutEffect(() => {
    if (user != null && isAgentMessage(message)) {
      setFeedback(message.feedback?.[user.uid])
    }
  }, [message, user])


  const noComment = useCallback(() => {
    setRequireComment(false)
  }, [])

  const submitFeedback = useCallback(
    async (liked: boolean | null, comment: string | null) => {
      if (liked == null && comment == null) {
        setFeedback(undefined)
      } else {
        setFeedback({liked: liked ?? false, comment, timestamp: Timestamp.now()})
      }

      setIsSubmitting(true)
      try {
        await saveFeedback(node.message.ref, liked, comment)
      } finally {
        setIsSubmitting(false)
        setRequireComment(liked === false && comment == null)
      }
    },
    [node.message.ref, saveFeedback]
  )

  return {feedback, submitFeedback, isSubmitting, requireComment, noComment}
}
