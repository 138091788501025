import type {QueryDocumentSnapshot} from 'firebase/firestore'
import {updateDoc} from 'firebase/firestore'
import type {Course} from '@goschool/model'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {Button, FormHelperText, Grid2 as Grid, Link, Paper, Popper, Stack, TextField, Typography} from '@mui/material'
import {Trans} from 'react-i18next'
import {MessageMarkdownComponent} from '@goschool/shared/chat'

export function CustomizeSystemPrompt({snapshot}: { snapshot: QueryDocumentSnapshot<Course> }) {
  const course = useMemo(
    () => snapshot.data(),
    [snapshot]
  )

  const [state, setState] = useState<'submitting' | 'idle' | 'failed'>('idle')
  const [error, setError] = useState<unknown | null>()
  const [prompt, setPrompt] = useState<string | null>()
  useEffect(
    () => {
      if (course != null) {
        setPrompt(course.system_prompt ?? defaultSystemPrompt(course))
      }
    },
    [course]
  )

  const submit = useCallback(
    async () => {
      if (prompt === snapshot.data().system_prompt) {
        return
      }
      setState('submitting')
      try {
        await updateDoc(snapshot.ref, {system_prompt: prompt})
        setState('idle')
      } catch (e) {
        setState('failed')
        setError(e)
      }
    },
    [prompt, snapshot]
  )

  const reset = useCallback(
    () => setPrompt(snapshot.data().system_prompt),
    [snapshot]
  )

  const factoryReset = useCallback(
    () => setPrompt(defaultSystemPrompt(course)),
    [course]
  )
  const [markdownAnchorEl, setMarkdownAnchorEl] = useState<null | HTMLElement>(null)
  const handleMarkdownHelpClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.preventDefault()
      setMarkdownAnchorEl((current) => {
        return current != null ? null : event.currentTarget
      })
    }, []
  )

  return <Grid container spacing={4}>
    <Grid size={6}>
      <Typography variant="h6" gutterBottom={true}><Trans
        i18nKey="course:customizeAssistant.systemPrompt"/></Typography>
      <FormHelperText>
        <Trans
          i18nKey="course:customizeAssistant.promptHelper"
          components={{
            1: <Link href="#" onClick={handleMarkdownHelpClick}/>
          }}
        />
      </FormHelperText>
      <Popper open={markdownAnchorEl != null} anchorEl={markdownAnchorEl} placement="bottom-start">
        <Paper sx={{
          margin: 4,
          whiteSpace: 'pre-line',
          maxWidth: '600px',
          maxHeight: '300px',
          overflow: 'scroll',
          padding: 2
        }}>
          <Typography variant="body2"><Trans i18nKey="course:customizeAssistant.markdownHelp"/></Typography>
        </Paper>
      </Popper>
      <TextField slotProps={{
        htmlInput: {
          style: {
            fontSize: '0.7rem',
            fontFamily: 'monospace',
            lineHeight: '1.2'
          }
        }
      }} multiline value={prompt}
      onChange={(e) => setPrompt(e.target.value)} fullWidth
      variant="outlined"/>
    </Grid>
    <Grid size={6}>
      <Typography variant="h6" gutterBottom={true}><Trans
        i18nKey="course:customizeAssistant.preview"/></Typography>
      <MessageMarkdownComponent>{prompt}</MessageMarkdownComponent>
    </Grid>

    <Grid size={12}>
      <Stack flexDirection="row" justifyContent="flex-end" gap={2} flexWrap="wrap">
        <Button disabled={course.system_prompt === prompt} loading={state === 'submitting'} variant="contained"
          onClick={submit}><Trans
            i18nKey="course:forms.submit"/></Button>
        <Button disabled={state === 'submitting'} variant="outlined" onClick={reset}><Trans
          i18nKey="course:customizeAssistant.reset"/></Button>
        <Button disabled={state === 'submitting'} variant="outlined" onClick={factoryReset}><Trans
          i18nKey="course:customizeAssistant.factoryReset"/></Button>
      </Stack>
    </Grid>
  </Grid>
}

function defaultSystemPrompt(course: Course) {
  return `# Context
You are an AI assistant for the course "**${course.title.trim()}**". Your goal is to enhance the student's learning experience by providing accurate, well-sourced, and clear answers. 

# Instructions

1. **Identify the student's language**  
   - Detect the language of the question (e.g., Hungarian, English) 
   - **Always respond in the same language**.

2. **Retrieve Relevant Information**  
   - Use the \`search\` to identify relevant content in the course material. Use it even if have your own thoughts.  


3. **Compose Your Response**  
   - Write a concise explanation in the student's language.  
   - Cite the used documents using APA 7th.
   - Make sure you mention in your response if you don't find relevant information.

4. **Mathematical Notation**  
   - For inline math, use \`$...$\`: $(x < 1)$  
   - For display math, use: \`$$\\n...\\n$$\` or \`\`\`math\\n...\\n\`\`\`:

\`\`\`math
\\begin{equation}
x_{1,2} = \\frac {-b \\pm \\sqrt {b^2 - 4ac}} {2a}
\\end{equation}
\`\`\`  

5. **Final Review**  
   - Double-check your answer is in the user's language.  
   - Verify LaTeX math is correct.  
   - Ensure you citations are appropriate or mention if you haven't find relevant information.

${course.instructor_guidelines != null ? `\n\n# Instructor guidelines\n${course.instructor_guidelines}\n\n` : ''}

# Available Tools
You can use the following functions without asking for permission:

1. **course_info**: Retrieves course title and description.
2. **course_documents**: Lists course documents with attributes (id, title, authors, language_code).
3. **search**: Retrieves relevant document segments based on queries.
`
}
