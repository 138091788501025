import type { GoSchoolUser } from '@goschool/model'
import { useUserContext } from './UserContext'
import { useAuthenticationContext } from './AuthenticationContext'
import { useCallback, useState } from 'react'
import { Avatar, Divider, IconButton, ListSubheader, Menu, MenuItem, styled } from '@mui/material'
import NoAccountsIcon from '@mui/icons-material/NoAccounts'
import { Trans } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { GoSchool } from '@goschool/routing'

export function AuthButton() {
  const { user, goSchoolUser, state } = useUserContext()
  const { signIn, signOut } = useAuthenticationContext()
  const navigate = useNavigate()

  const handleSignOut = useCallback(() => {
    signOut()
    navigate(GoSchool.home)
  }, [navigate, signOut])

  const [menuAnchor, setMenuAnchor] = useState<HTMLElement | null>(null)

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setMenuAnchor(event.currentTarget)
  }

  const handleCloseUserMenu = () => {
    setMenuAnchor(null)
  }

  if (state==='pending' || goSchoolUser===undefined) {
    return null
  }

  if (goSchoolUser==null || user==null) {
    return <IconButton edge="end" size="small" onClick={signIn} color="secondary"><NoAccountsIcon /></IconButton>
  } else {
    return <>
      <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }} color="secondary">
        <UserAvatar
          alt={user.displayName ?? ''}
          src={user.photoURL ?? undefined}
        />
      </IconButton>
      <Menu
        anchorEl={menuAnchor} keepMounted
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={Boolean(menuAnchor)} onClose={handleCloseUserMenu}>
        <OrgSelector user={goSchoolUser} closeMenu={handleCloseUserMenu} />
        <MenuItem key="signout" onClick={handleSignOut}>
          <Trans i18nKey="auth:signOut.cta" />
        </MenuItem>
      </Menu>
    </>
  }
}


function OrgSelector({ user, closeMenu }: { user: GoSchoolUser, closeMenu: () => void }) {
  const { selectOrganization } = useUserContext()
  const navigate = useNavigate()
  if (user.organizations.length < 2) {
    return null
  }
  return <>
    <ListSubheader>Select Org</ListSubheader>
    {user.organizations.map(org =>
      <MenuItem dense key={org.ref.path} selected={user.organization.path===org.ref.path}
        onClick={() => selectOrganization(org.ref).then(closeMenu)}>{
          org.name
        }</MenuItem>
    )}
    <Divider />
  </>
}


const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(3),
  height: theme.spacing(3)
}))
