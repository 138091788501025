import {StrictMode} from 'react'
import * as ReactDOM from 'react-dom/client'
import {FirebaseContextProvider} from '@goschool/react-firebase'
import {CssBaseline, ThemeProvider} from '@mui/material'
import '@goschool/i18n'

import {AuthenticationContextProvider, UserContextProvider} from '@goschool/auth'
import {NotFound} from './app'
import {GoSchoolRoutes, LandingPage} from '@goschool/site'

import * as Sentry from '@sentry/react'
import {goSchoolTheme} from './theme'
import {CustomGoSchoolContextProvider} from '@goschool/components'
import {BrowserRouter} from 'react-router-dom'
import {brandingConfig, customAuth, firebaseConfig, sentryConfig} from './config'

Sentry.init(sentryConfig)

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <StrictMode>
    <CustomGoSchoolContextProvider {...brandingConfig}>
      <ThemeProvider theme={goSchoolTheme}>
        <CssBaseline/>
        <FirebaseContextProvider options={firebaseConfig}>
          <UserContextProvider>
            <BrowserRouter>
              <AuthenticationContextProvider customConfigs={customAuth}
                enabledMethods={['email-password', 'google', 'microsoft']}>
                <GoSchoolRoutes App={LandingPage} NotFound={NotFound}/>
              </AuthenticationContextProvider>
            </BrowserRouter>
          </UserContextProvider>
        </FirebaseContextProvider>
      </ThemeProvider>
    </CustomGoSchoolContextProvider>
  </StrictMode>
)



