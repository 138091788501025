import {typeConverter, useFirestore, useFirestoreResults} from '@goschool/react-firebase'
import {useMemo} from 'react'
import {collection, orderBy, query} from 'firebase/firestore'
import type {Organization} from '../Organization'

export function useOrganizations(){
  const firestore = useFirestore()
  return useFirestoreResults(useMemo(
    () => query(
      collection(firestore, 'organizations').withConverter(typeConverter<Organization>()),
      orderBy('name', 'asc')
    ),
    [firestore]
  ))
}
