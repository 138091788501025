import type {Course} from '@goschool/model'
import {Avatar, Card, CardActionArea, CardActions, CardHeader, Skeleton, styled} from '@mui/material'
import {GoSchool} from '@goschool/routing'
import {useEffect, useMemo, useState} from 'react'
import {Link as RouterLink} from 'react-router-dom'
import {useCourseContext} from '@goschool/dao'
import {InstructorActionLink, StudentActionLink} from './CourseActions'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks'
import SettingsIcon from '@mui/icons-material/Settings'
import {useTranslation} from 'react-i18next'


export function CourseCard() {
  const {courseSnapshot} = useCourseContext()
  const course = useMemo(() => courseSnapshot.data(), [courseSnapshot])

  const instructorNames = useMemo(
    () => {
      return course.instructors.map(instructor => instructor.name).join(', ')
    },
    [course]
  )

  return <StyledCourseCard variant="elevation">
    <CardActionArea
      component={RouterLink}
      to={GoSchool.courseChat(courseSnapshot.ref)}>
      <CardHeader avatar={<CourseAvatar course={course}/>} title={course.title}
        subheader={instructorNames ?? <Skeleton/>}
      />
    </CardActionArea>

    <CardActions
      disableSpacing><CourseActions/></CardActions>
  </StyledCourseCard>
}


function CourseActions() {
  const {courseSnapshot, courseRoles} = useCourseContext()
  const isInstructor = useMemo(
    () => courseRoles.includes('instructor') || courseRoles.includes('admin'),
    [courseRoles]
  )
  const {t} = useTranslation()
  if (isInstructor) {
    return <>
      <InstructorActionLink
        color="inherit" title={t('course:actions.studentChats')} edge="end"
        to={GoSchool.courseChats(courseSnapshot.ref)}
        Icon={ForumOutlinedIcon}/>
      <InstructorActionLink
        color="inherit" title={t('course:actions.documents')}
        to={GoSchool.courseDocuments(courseSnapshot.ref)}
        Icon={LibraryBooksIcon}/>
      <InstructorActionLink
        color="inherit" title={t('course:actions.details')} to={GoSchool.course(courseSnapshot.ref)}
        Icon={SettingsIcon}/>
    </>
  } else {
    return <>
      <StudentActionLink
        color="inherit" title={t('course:actions.documents')} edge="end"
        to={GoSchool.courseDocuments(courseSnapshot.ref)}
        Icon={LibraryBooksIcon}/>
      <StudentActionLink
        color="inherit" title={t('course:actions.details')} to={GoSchool.course(courseSnapshot.ref)}
        Icon={SettingsIcon}/>
    </>
  }
}

function CourseAvatar({course}: { course: Course }) {

  if (course.banner != null) {
    return <Avatar src={course.banner}/>
  } else {
    return <Avatar>{course.title.slice(0, 2).toUpperCase()}</Avatar>
  }
}

function useAverageColor(url: string | null | undefined): string {
  const [rgb, setRgb] = useState<RGB>([255, 255, 255])
  useEffect(
    () => {
      if (url != null) {
        const canvas = document.createElement('canvas')
        canvas.width = 1024
        canvas.height = 1024
        const ctx = canvas.getContext('2d', {willReadFrequently: true})
        if (ctx != null) {
          const img = new Image()
          img.onload = function () {
            img.crossOrigin = 'Anonymous'
            ctx.drawImage(img, 0, 0)
            const rgb = getAverageRGB(canvas)
            setRgb(rgb)
          }
          img.src = url
        }
      }

    }, [url])

  const color = useMemo(
    () => `rgb(${rgb.join(',')})`,
    [rgb]
  )
  return color
}

type RGB = [r: number, g: number, b: number]

function getAverageRGB(canvas: HTMLCanvasElement): RGB {
  const blockSize = 5
  const defaultRGB: RGB = [255, 255, 255]
  const rgb: RGB = [0, 0, 0]

  const context = canvas.getContext('2d', {willReadFrequently: true})
  if (context == null) {
    return defaultRGB
  }

  const height = canvas.height
  const width = canvas.width

  try {
    const data = context.getImageData(0, 0, width, height)
    const length = data.data.length

    let i = -4
    let count = 0
    while ((i += blockSize * 4) < length) {
      ++count
      rgb[0] += data.data[i + 0]
      rgb[1] += data.data[i + 1]
      rgb[2] += data.data[i + 2]
    }


    return rgb.map(channel => ~~(channel / count)) as RGB
  } catch (e) {
    return defaultRGB
  }


}

const StyledCourseCard = styled(Card, {name: 'StyledCourseCard', slot: 'Root'})(
  ({theme}) => ({
    '& .MuiCardHeader-root': {
      height: '6em',
      '& .MuiAvatar-root': {
        width: theme.spacing(8),
        height: theme.spacing(8)
      }
    },
    '& .MuiCardActions-root': {
      borderTop: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1),
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      alignItems: 'stretch'
    }
  })
)
