import {colors, Stack} from '@mui/material'
import {PageLayout} from './PageLayout'
import type {SVGAttributes} from 'react'

export function LoadingPage() {
  return <PageLayout fullScreen={true}>
    <Stack gap={2} justifyContent="center" alignItems="center" flexGrow={1}>
      <StdyLoadingAnimation style={{maxHeight: '10rem'}}/>
    </Stack>
  </PageLayout>
}


export function StdyLoadingAnimation(props: SVGAttributes<SVGSVGElement>) {
  const logoColor = colors.lightBlue['300']
  return <svg viewBox="0 0 430 430" {...props}>
    <path fill="none" stroke={logoColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="20"
      d="M381 176v70"/>
    <path fill={logoColor} d="M357 285v-25c0-14 10-24 24-24s24 10 24 24v25z"/>
    <g fill="none" stroke={logoColor} strokeLinecap="round" strokeLinejoin="round" strokeWidth="28">
      <path d="m39 133 169-58 166 56s16 7 16 18c1 11-17 19-17 19l-143 50"/>
      <path d="M102 204v56c0 41 35 97 102 99 62 2 107-55 107-96l1-64"/>
    </g>
  </svg>
}

