import {Box, Button, Grid2 as Grid, Stack, Typography} from '@mui/material'
import {Breadcrumb, FixedFab, LoadingPage, PageLayout} from '@goschool/components'
import {Trans} from 'react-i18next'
import AddIcon from '@mui/icons-material/Add'
import {useMemo, useState} from 'react'
import {useUserContext} from '@goschool/auth'
import {CreateCourseDialog} from '../components/CreateCourseDialog'

import {GoSchool} from '@goschool/routing'
import type {DocumentSnapshot} from 'firebase/firestore'
import type {Organization} from '@goschool/model'
import {useOrganizationCourses} from '@goschool/model'
import {CourseCard} from '../components/CourseCard'
import {CourseContextProvider} from '@goschool/dao'
import {OrganizationSelector, useSelectedOrganization} from '../components/OrganizationSelector'
import {Link as RouterLink} from 'react-router-dom'

export function AdminBreadcrumb() {
  return <Breadcrumb to={GoSchool.admin}>
    <Trans i18nKey="course:catalog.title"/>
  </Breadcrumb>
}


export function AdminPage() {
  const {roles, user, goSchoolUser, state} = useUserContext()
  const [creating, setCreating] = useState(false)

  const organization = useSelectedOrganization()

  if (user === undefined || state === 'pending' || goSchoolUser === undefined) {
    return <LoadingPage/>
  }


  return <PageLayout fullScreen={false} breadcrumbs={[]}>
    <Typography variant="h4" component="h1" gutterBottom={false}><Trans
      i18nKey="admin:courses.title"/></Typography>
    <Stack direction="column" gap={4} alignItems="stretch">
      <Stack direction="row" gap={2} justifyContent="stretch">
        <OrganizationSelector/>
        <Stack direction="row" gap={2} justifyContent="flex-end" flexGrow={1}>
          <Button component={RouterLink} to={'invitations'} variant="outlined">Meghívók</Button>
          <Button component={RouterLink} to={'chats'} variant="outlined">Chatek</Button>
        </Stack>
      </Stack>
      {<OrganizationAdmin snapshot={organization}/>}
    </Stack>
    {roles.includes('instructor') &&
        <FixedFab onClick={() => setCreating(true)} color="primary" horizontal="right" vertical="bottom">
          <AddIcon/>
        </FixedFab>
    }
    <CreateCourseDialog organizationRef={organization?.ref} displayed={creating} hide={() => setCreating(false)}/>
  </PageLayout>
}

function OrganizationAdmin({snapshot}: { snapshot: DocumentSnapshot<Organization> | undefined | null }) {
  const courses = useOrganizationCourses(snapshot)
  const organization = useMemo(
    () => snapshot?.data(),
    [snapshot]
  )


  if (organization == null) {
    return null
  }

  return <Box>
    <Grid container spacing={4} rowSpacing={4}>
      {(courses ?? []).map(
        (course) =>
          <CourseContextProvider key={course.ref.path} courseSnapshot={course}>
            <Grid size={{xs: 12, md: 4, sm: 6}}>
              <CourseCard/>
            </Grid>
          </CourseContextProvider>
      )}</Grid>
  </Box>
}

