import type {DocumentReference} from 'firebase/firestore'
import type {Course, CourseChat, CourseDocument, GoSchoolInvitation, Organization} from '@goschool/model'
import {courseConverter, organizationConverter} from '@goschool/model'

export const home = '/'

export const organizations = `${home}organizations`
export const invitations = `${home}invitations`


export const courses = `${home}courses`
export const termsOfUse = `${home}terms-of-use`
export const privacyStatement = `${home}privacy-statement`
export const admin = `${home}admin`


export function organization(reference: DocumentReference<Organization> | null | undefined) {
  if (reference == null) {
    return undefined
  }
  const id = reference.id
  return `${organizations}/${id}`
}

export function invitation(reference: DocumentReference<GoSchoolInvitation>): string
export function invitation(reference: DocumentReference<GoSchoolInvitation> | null | undefined): string | undefined
export function invitation(reference: DocumentReference<GoSchoolInvitation> | null | undefined) {
  if (reference == null) {
    return undefined
  }
  const id = reference.id
  return `${invitations}/${id}`
}


export function adminOrganization(reference: DocumentReference<Organization>): string
export function adminOrganization(reference: DocumentReference<Organization> | null | undefined): string | undefined
export function adminOrganization(reference: DocumentReference<Organization> | null | undefined) {
  if (reference == null) {
    return undefined
  }
  const id = reference.id
  return `${organizations}/${id}`
}

export function course(reference: DocumentReference<Course>): string
export function course(reference: DocumentReference<Course> | null | undefined): string | undefined
export function course(reference: DocumentReference<Course> | null | undefined) {
  if (reference == null) {
    return undefined
  }
  const organizationRef = reference?.parent.parent?.withConverter(
    organizationConverter
  )

  if (organizationRef == null) {
    throw new Error('Course reference does not have a parent organization')
  }

  return `${organization(organizationRef)}/courses/${reference.id}`
}


export function courseEdit(reference: DocumentReference<Course>): string
export function courseEdit(reference: DocumentReference<Course> | null | undefined): string | undefined
export function courseEdit(reference: DocumentReference<Course> | null | undefined) {
  if (reference == null) {
    return undefined
  }
  const organizationRef = reference?.parent.parent?.withConverter(
    organizationConverter
  )

  if (organizationRef == null) {
    throw new Error('Course reference does not have a parent organization')
  }

  return `${organization(organizationRef)}/courses/${reference.id}/edit`
}

export function courseCustomizeAssistant(reference: DocumentReference<Course>): string
export function courseCustomizeAssistant(reference: DocumentReference<Course> | null | undefined): string | undefined
export function courseCustomizeAssistant(reference: DocumentReference<Course> | null | undefined) {
  if (reference == null) {
    return undefined
  }
  const organizationRef = reference?.parent.parent?.withConverter(
    organizationConverter
  )

  if (organizationRef == null) {
    throw new Error('Course reference does not have a parent organization')
  }

  return `${organization(organizationRef)}/courses/${reference.id}/customize-assistant`
}

export function courseDocuments(reference: DocumentReference<Course>): string
export function courseDocuments(reference: DocumentReference<Course> | null | undefined): string | undefined
export function courseDocuments(reference: DocumentReference<Course> | null | undefined) {
  if (reference == null) {
    return undefined
  }
  const organizationRef = reference?.parent.parent?.withConverter(
    organizationConverter
  )

  if (organizationRef == null) {
    throw new Error('Course reference does not have a parent organization')
  }

  return `${organization(organizationRef)}/courses/${reference.id}/documents`
}

export function document(reference: DocumentReference<CourseDocument>): string
export function document(reference: DocumentReference<CourseDocument> | null | undefined): string | undefined
export function document(reference: DocumentReference<CourseDocument> | null | undefined) {
  if (reference == null) {
    return undefined
  }
  const courseRef = reference?.parent.parent?.withConverter(courseConverter)

  if (courseRef == null) {
    throw new Error('Document reference does not exist')
  }

  return `${course(courseRef)}/documents/${reference.id}`
}

export function courseChat(reference: DocumentReference<Course>): string
export function courseChat(reference: DocumentReference<Course> | null | undefined): string | undefined
export function courseChat(reference: DocumentReference<Course> | null | undefined) {
  if (reference === undefined) {
    return undefined
  }

  if (reference == null) {
    throw new Error('Document reference does not exist')
  }

  return `${course(reference)}/chat`
}

export function courseStudents(reference: DocumentReference<Course>): string
export function courseStudents(reference: DocumentReference<Course> | null | undefined): string | undefined
export function courseStudents(reference: DocumentReference<Course> | null | undefined) {
  if (reference === undefined) {
    return undefined
  }

  if (reference == null) {
    throw new Error('Document reference does not exist')
  }

  return `${course(reference)}/students`
}

export function courseChats(reference: DocumentReference<Course>): string
export function courseChats(reference: DocumentReference<Course> | null | undefined): string | undefined
export function courseChats(reference: DocumentReference<Course> | null | undefined) {
  if (reference === undefined) {
    return undefined
  }

  if (reference == null) {
    throw new Error('Document reference does not exist')
  }

  return `${course(reference)}/chats`
}

export function courseChatView(reference: DocumentReference<CourseChat>): string
export function courseChatView(reference: DocumentReference<CourseChat> | null | undefined): string | undefined
export function courseChatView(reference: DocumentReference<CourseChat> | null | undefined) {
  if (reference === undefined) {
    return undefined
  }
  if (reference == null) {
    throw new Error('Document reference does not exist')
  }

  const courseRef = reference?.parent.parent?.withConverter(courseConverter)
  if (courseRef == null) {
    throw new Error('Course chat reference does not have a parent course')
  }


  return `${course(courseRef)}/chats/${reference.id}`
}
