import {Button, DialogActions, DialogContent, DialogTitle, FormHelperText, Stack, TextField} from '@mui/material'
import {Trans, useTranslation} from 'react-i18next'
import type {FormEvent} from 'react'
import {useCallback, useState} from 'react'
import {sendPasswordResetEmail} from 'firebase/auth'
import {useAuthenticationContext} from '../AuthenticationContext'
import {useFirebaseAuth} from '@goschool/react-firebase'
import {FirebaseError} from 'firebase/app'
import {errorMessage} from './util'

export function PasswordResetForm() {
  const {t} = useTranslation()
  const {auth} = useFirebaseAuth()
  const {hideAuthentication, signIn} = useAuthenticationContext()

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState<string | null>(null)
  const [error, setError] = useState<string | null>(null)


  const [state, setState] = useState<'idle' | 'pending' | 'success' | 'failed'>('idle')
  const submit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      if (!['idle', 'failed'].includes(state)) {
        throw new Error(`Cannot submit while in state ${state}`)
      }

      setState('pending')
      setError(null)
      setEmailError(null)
      try {
        await sendPasswordResetEmail(auth, email)
        setState('success')
        hideAuthentication()
        setEmail('')
        setState('idle')
      } catch (e) {
        if (e instanceof FirebaseError) {
          if (e.code === 'auth/invalid-email') {
            setEmailError('Invalid email')
          } else {
            setError(errorMessage(e))
          }
        } else {
          setError(errorMessage(e))
        }
        setState('failed')
      }
    },
    [auth, email, hideAuthentication, state]
  )

  return <form onSubmit={submit}>
    <DialogTitle><Trans i18nKey="auth:resetPassword.form.title"/></DialogTitle>

    <DialogContent>
      <Stack gap={1} flexGrow={1}>
        <TextField
          size="small" fullWidth={true} margin="normal"
          label={t('auth:resetPassword.form.email')} value={email}
          error={emailError != null} helperText={emailError}
          onChange={e => setEmail(e.target.value)}/>


        {error != null && <FormHelperText error={true}>{error}</FormHelperText>}

      </Stack>
    </DialogContent>
    <DialogActions>
      <Button variant="outlined" onClick={signIn} size="small"><Trans
        i18nKey="auth:resetPassword.form.cancel"/></Button>

      <Button sx={{flexShrink: 0}} type="submit" variant="contained" color="primary"
        loading={state === 'pending'} autoFocus={true}>
        <Trans i18nKey="auth:resetPassword.form.submit"/>
      </Button>
    </DialogActions>
  </form>
}

