import type {AppBarProps} from '@mui/material'
import {AppBar as MuiAppBar, IconButton, styled, Toolbar, useMediaQuery, useTheme} from '@mui/material'
import {PageBreadcrumbs, PageLayout} from '@goschool/components'
import {useParams} from 'react-router-dom'
import type {PropsWithChildren} from 'react'
import {useCallback, useLayoutEffect, useMemo, useState} from 'react'

import type {DocumentSnapshot, QueryDocumentSnapshot} from 'firebase/firestore'
import type {Course, CourseChat} from '@goschool/model'
import {useCourse} from '@goschool/model'
import {AuthButton} from '@goschool/auth'

import {CourseChatList} from './components/CourseChatList'
import {ChatContextProvider, ChatMessages, ChatPrompt, InitialPrompt, useChatContext} from '@goschool/shared/chat'
import {useFirestoreSnapshot} from '@goschool/react-firebase'
import ViewListRoundedIcon from '@mui/icons-material/ViewListRounded'
import EditIcon from '@mui/icons-material/Edit'
import {GoSchool} from '@goschool/routing'
import {useChats} from './components/useChats'
import {useCoursePageBreadcrumb} from '@goschool/courses'


export function CourseChatPage() {
  const {organizationId, courseId} = useParams<{
    organizationId: string
    courseId: string
  }>()

  const courseSnapshot = useCourse(organizationId, courseId)


  if (courseSnapshot == null || !courseSnapshot.exists()) {
    return null
  }

  return <CourseChatLayout courseSnapshot={courseSnapshot}/>
}

function useCourseChatBreadcrumbs(
  courseSnapshot: QueryDocumentSnapshot<Course>,
  selectedChat: DocumentSnapshot<CourseChat> | null | undefined) {
  const courseBreadcrumbs = useCoursePageBreadcrumb(courseSnapshot)
  if (!courseSnapshot.exists()) {
    return []
  }
  return [
    ...courseBreadcrumbs,
    {
      title: selectedChat?.data()?.title ?? 'New Chat',
      href: GoSchool.courseChat(courseSnapshot.ref)
    }
  ]
}

function CourseChatLayout({courseSnapshot}: { courseSnapshot: QueryDocumentSnapshot<Course> }) {
  const theme = useTheme()
  const isNarrow = useMediaQuery(theme.breakpoints.down('md'))
  const [isOpen, setOpen] = useState<boolean>(!isNarrow)
  const toggleOpen = useCallback(() => setOpen((open) => !open), [setOpen])

  const sidebarWidth = useMemo(
    () => isNarrow ? '100vw' : '250px',
    [isNarrow]
  )


  useLayoutEffect(() => setOpen(!isNarrow), [isNarrow])

  const handleChatSelect = useCallback(
    () => {
      if (isNarrow) {
        setOpen(false)
      }
    }, [isNarrow])

  const {chats, selectedChat, selectChat, chatManager, createChat} = useChats(courseSnapshot, handleChatSelect)
  const chatSnapshot = useFirestoreSnapshot(selectedChat)
  return <PageLayout fullScreen={true}>
    <ChatContextProvider chatManager={chatManager} mode="chat"
      conversationStarters={courseSnapshot.data().conversation_starters}>
      <AppBar position="static" sidebarWidth={sidebarWidth} isSidebarOpen={isOpen}>
        <Toolbar variant="dense" disableGutters={true}>
          <IconButton edge="start" size="small" onClick={toggleOpen}><ViewListRoundedIcon/></IconButton>
          <IconButton edge="end" size="small" onClick={createChat}><EditIcon/></IconButton>
        </Toolbar>
        <PageBreadcrumbs breadcrumbs={useCourseChatBreadcrumbs(courseSnapshot, chatSnapshot)}/>
        <AuthButton/>
      </AppBar>

      <ChatLayout sidebarWidth={sidebarWidth} isSidebarOpen={isOpen}>
        <Sidebar>
          <CourseChatList chats={chats} selectedChat={selectedChat} selectChat={selectChat}/>
        </Sidebar>
        <Content>
          <ChatContent/>
        </Content>
      </ChatLayout>
    </ChatContextProvider>
  </PageLayout>
}


function Sidebar({children}: PropsWithChildren) {
  return <div className="ChatLayout-Sidebar">
    {children}
  </div>
}


function ChatContent() {
  const {chatManager, managerState} = useChatContext()

  if (managerState === 'ready' && chatManager.thread.length === 0) {
    return <InitialPrompt/>
  }

  return <>
    <div className="Chat-Messages"><ChatMessages/></div>
    <div className="Chat-Prompt"><ChatPrompt/></div>
  </>
}

function Content({children}: PropsWithChildren) {
  return <div className="ChatLayout-Content">
    {children}
  </div>
}

interface ChatLayoutProps extends PropsWithChildren {
  sidebarWidth: string;
  isSidebarOpen: boolean;
}

const AppBar = styled(MuiAppBar, {
  name: 'AppBar',
  slot: 'Root',
  shouldForwardProp: (propName) => propName !== 'sidebarWidth' && propName !== 'isSidebarOpen'
})<ChatLayoutProps & AppBarProps>(
  ({theme, sidebarWidth, isSidebarOpen}) => ({
    flex: '0 0 auto',
    flexDirection: 'row',
    justifyContent: 'stretch',
    alignItems: 'center',
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    '& .MuiToolbar-root': {
      flex: '0 0 auto',
      display: 'flex',
      justifyContent: 'flex-start',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen
      }),
      width: isSidebarOpen ? sidebarWidth : 'auto'
    },
    '& .MuiBreadcrumbs-root': {
      flex: '1 1 0',
      display: 'flex',
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      flexDirection: 'row',
      gap: theme.spacing(1),
      alignItems: 'center',
      justifyContent: 'flex-start',
      '& .MuiBreadcrumbs-ol': {
        whiteSpace: 'nowrap',
        flexWrap: 'nowrap',
        overflow: 'hidden'
      }
    }
  })
)


const ChatLayout = styled(
  'div', {
    name: 'ChatLayout',
    slot: 'Root',
    shouldForwardProp: (propName) => propName !== 'sidebarWidth' && propName !== 'isSidebarOpen'
  })<ChatLayoutProps>(
    ({theme, sidebarWidth, isSidebarOpen}) => ({
      flex: '1 0 auto',
      flexDirection: 'row',
      display: 'flex',
      alignItems: 'stretch',
      justifyContent: 'stretch',
      overflow: 'hidden',

      '& .ChatLayout-Sidebar': {
        display: 'flex',
        flegGrow: 0,
        flexShrink: 0,
        flexDirection: 'column',
        alignItems: 'stretch',
        justifyContent: 'stretch',
        backgroundColor: theme.palette.background.paper,
        overflow: 'hidden',

        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.enteringScreen
        }),
        width: isSidebarOpen ? sidebarWidth : 0,

        '& > *': {
          flex: '1 1 0'
        }
      },
      '& .ChatLayout-Content': {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 0',
        gap: theme.spacing(0),
        backgroundColor: theme.palette.background.default,
        paddingLeft: theme.spacing(4),
        paddingRight: theme.spacing(4),
        '& > .Chat-Messages': {
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing(4),
          flex: '1 1 0',
          overflowY: 'auto',
          overflowX: 'hidden',

          paddingBottom: theme.spacing(2),
          paddingTop: theme.spacing(1)
        },
        '& > .Chat-Prompt': {
          flex: '0 0 200ox',
          paddingBottom: theme.spacing(2)
        }
      }
    })
  )
