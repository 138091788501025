import {typeConverter, useFirestore, useFirestoreResults, useFirestoreSnapshot} from '@goschool/react-firebase'
import {useMemo} from 'react'
import type {DocumentReference, QueryDocumentSnapshot} from 'firebase/firestore'
import {collection, doc, orderBy, query} from 'firebase/firestore'
import type {Course} from '../Course'
import type {Organization} from '../Organization'
import type {DocumentSnapshot} from '@firebase/firestore'
import {courseConverter, courseDocumentConverter} from '../converters'
import type {GoSchoolUser} from '../GoSchoolUser'

export function useOrganizationCourses(organizationSnapshot: DocumentSnapshot<Organization> | null | undefined) {
  const courses = useFirestoreResults(useMemo(
    () => organizationSnapshot?.exists ?
      query(
        collection(organizationSnapshot.ref, 'courses').withConverter(typeConverter<Course>()),
        orderBy('title', 'asc')
      ):undefined,
    [organizationSnapshot]
  ))

  return courses
}

export function useCourseDocuments(courseSnapshot: QueryDocumentSnapshot<Course> | null | undefined) {
  const courseDocumentsCollectionRef = useMemo(
    () => {
      if (courseSnapshot!=null) {
        return collection(courseSnapshot.ref, 'documents').withConverter(courseDocumentConverter)
      }

      return undefined
    }, [courseSnapshot]
  )

  const courseDocuments = useFirestoreResults(courseDocumentsCollectionRef)

  return {courseDocuments, courseDocumentsCollectionRef}
}

export function useCourse(organizationId?: string, courseId?: string) {

  const firestore = useFirestore()

  const ref = useMemo(
    () => {
      if (organizationId!=null && courseId!=null) {
        return doc(firestore,
          'organizations', organizationId,
          'courses', courseId
        ).withConverter(courseConverter)
      } else {
        return undefined
      }
    }, [organizationId, courseId, firestore]
  )

  return useFirestoreSnapshot(ref)
}


export interface UserReference {
  name: string,
  user: DocumentReference<GoSchoolUser>
}

export function useCourseInstructors(courseSnapshot?: DocumentSnapshot<Course> | null): QueryDocumentSnapshot<UserReference>[] | null | undefined {
  const q = useMemo(
    () => {
      if (courseSnapshot==null || !courseSnapshot.exists()) {
        return null
      }
      return query(
        collection(courseSnapshot.ref, 'instructors').withConverter(typeConverter<UserReference>()),
        orderBy('name', 'asc')
      )
    },
    [courseSnapshot]
  )

  return useFirestoreResults(q)
}
