import type { FabProps } from '@mui/material'
import { Fab, styled } from '@mui/material'


interface FixedFabProps extends FabProps {
  vertical: 'top' | 'bottom' | 'center';
  horizontal: 'left' | 'right' | 'center';
  offset?: number;
}

export const FixedFab = styled(Fab, {
  name: 'FixedFab', slot: 'Root',
  shouldForwardProp: (prop) => prop!=='vertical' && prop!=='horizontal' && prop!=='offset'
})<FixedFabProps>(
  ({ theme, horizontal, vertical, offset = 4 }) => {
    const horizontalPosition =
      horizontal==='left'
        ? { left: theme.spacing(offset) }
        :horizontal==='center'
          ? { left: '50%', transform: 'translateX(-50%)' }
          :{ right: theme.spacing(offset) }

    const verticalPosition =
      vertical==='top'
        ? { top: theme.spacing(offset) }
        :vertical==='center'
          ? { top: '50%', transform: 'translateY(-50%)' }
          :{ bottom: theme.spacing(offset) }

    return {
      ...horizontalPosition,
      ...verticalPosition,
      position: 'fixed',
      zIndex: theme.zIndex.speedDial,
      '&.MuiFab-extended': {
        width: 'auto',
        whiteSpace: 'nowrap',
        '& .MuiSvgIcon-root': {
          marginRight: theme.spacing(1)
        }
      }
    }
  }
)
