import {colors, createTheme} from '@mui/material'

const {palette} = createTheme()
export const darkPalette = {
  mode: 'dark',
  background: {
    default: colors.indigo['900'],
    paper: colors.indigo['600']
  },
  primary: {
    main: colors.blue['300']
  },
  secondary: {
    main: colors.amber['A700']
  }
} as const

export const lightPalette = {
  mode: 'light',
  background: {
    paper: colors.grey['100'],
    default: colors.common.white
  },
  primary: palette.augmentColor({color: {main: colors.indigo['900']}}),
  secondary: palette.augmentColor({color: {main: colors.lightBlue['300']}})
} as const
