import {PageLayout} from '@goschool/components'
import {useParams} from 'react-router-dom'
import {NotFound} from '@goschool/react-firebase'
import {CourseContextProvider} from '@goschool/dao'
import type {SyntheticEvent} from 'react'
import {useCallback, useMemo, useState} from 'react'
import {GoSchool} from '@goschool/routing'
import type {Course} from '@goschool/model'
import {useCourse} from '@goschool/model'
import {useCoursePageBreadcrumb} from '../CoursePage'
import {useTranslation} from 'react-i18next'
import {CoursePageHeader} from './components/CoursePageHeader'
import {CustomizeSystemPrompt} from './assistant/CustomizeSystemPrompt'
import {Box, Tab} from '@mui/material'
import type {QueryDocumentSnapshot} from 'firebase/firestore'
import {TabContext, TabList, TabPanel} from '@mui/lab'
import {ConversationStarters} from './assistant/ConversationStarters'

export function CustomizeCourseAssistantPage() {
  const {organizationId, courseId} = useParams<{
    organizationId: string;
    courseId: string;
  }>()

  const courseSnapshot = useCourse(organizationId, courseId)
  const course = useMemo(
    () => courseSnapshot === null ? null : courseSnapshot?.data(),
    [courseSnapshot]
  )
  const {t} = useTranslation()
  const courseBreadcrumbs = useCoursePageBreadcrumb(courseSnapshot)
  const breadcrumbs = useMemo(
    () => {
      if (!courseSnapshot?.exists()) {
        return []
      }
      return [
        ...courseBreadcrumbs,
        {
          title: t('course:actions.customizeAssistant'),
          href: GoSchool.courseEdit(courseSnapshot.ref)
        }
      ]
    }, [courseBreadcrumbs, courseSnapshot, t]
  )


  if (courseSnapshot === undefined) {
    return null
  }


  if (!courseSnapshot?.exists() || course == null) {
    throw new NotFound()
  }

  return (
    <PageLayout fullScreen={false} breadcrumbs={breadcrumbs}>
      <CourseContextProvider courseSnapshot={courseSnapshot}>
        <CoursePageHeader courseSnapshot={courseSnapshot}/>
        <CustomizationTabs courseSnapshot={courseSnapshot}/>
      </CourseContextProvider>
    </PageLayout>
  )
}


type TabId = 'systemPrompt' | 'conversationStarters'

function CustomizationTabs({courseSnapshot}: { courseSnapshot: QueryDocumentSnapshot<Course> }) {
  const {t} = useTranslation()

  const [selectedTab, setSelectedTab] = useState<number>(0)
  const selectTab = useCallback(
    (event: SyntheticEvent, index: number) => {
      setSelectedTab(index)
    }, []
  )


  const tabLabel = useCallback(
    (tab: TabId) => t(`course:customizeAssistant.${tab}`),
    [t]
  )

  return <TabContext value={selectedTab}>
    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
      <TabList onChange={selectTab}>
        <Tab id={'tab-conversationStarters'} value={0} label={tabLabel('conversationStarters')}/>
        <Tab id={'tab-systemPrompt'} value={1} label={tabLabel('systemPrompt')}/>
      </TabList>
    </Box>
    <TabPanel value={0}>
      <ConversationStarters snapshot={courseSnapshot}/>
    </TabPanel>
    <TabPanel value={1}>
      <CustomizeSystemPrompt snapshot={courseSnapshot}/>
    </TabPanel>
  </TabContext>
}
