import type {QueryDocumentSnapshot} from 'firebase/firestore'
import type {CourseChat} from '@goschool/model'
import type {ReactNode} from 'react'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {Link, List, ListItem, ListItemText, Skeleton, Stack, styled, Typography} from '@mui/material'
import {TransRelTime} from '@goschool/i18n'
import {GoSchool} from '@goschool/routing'
import {Link as RouterLink} from 'react-router-dom'

export function CourseChatsList({chats, noChatsAction}: {
  chats: QueryDocumentSnapshot<CourseChat>[] | null | undefined,
  noChatsAction?: ReactNode
}) {
  const {t} = useTranslation()
  if (chats == null) {
    return <StyledChatList dense disablePadding>
      <ListItem dense disablePadding disableGutters>
        <ListItemText
          primary={<Skeleton variant="text" width={200}/>}
          secondary={<Skeleton variant="text" width={100}/>}/>
      </ListItem>
      <ListItem dense disablePadding disableGutters>
        <ListItemText
          primary={<Skeleton variant="text" width={200}/>}
          secondary={<Skeleton variant="text" width={100}/>}/>
      </ListItem>
      <ListItem dense disablePadding disableGutters>
        <ListItemText
          primary={<Skeleton variant="text" width={200}/>}
          secondary={<Skeleton variant="text" width={100}/>}/>
      </ListItem>
    </StyledChatList>
  }

  if (chats.length === 0) {
    return <Stack direction="column" gap={1} alignItems="flex-start" justifyContent="flex-start">
      <Typography variant="body2">{t('course:details.noChats')}</Typography>
      {noChatsAction && noChatsAction}
    </Stack>
  }
  return <StyledChatList dense disablePadding>{
    chats.map(
      (chat, i) => <ChatListItem key={i} snapshot={chat}/>
    )
  }</StyledChatList>
}

function ChatListItem({snapshot}: { snapshot: QueryDocumentSnapshot<CourseChat> }) {
  const chat = useMemo(
    () => snapshot.data(),
    [snapshot]
  )

  return <ListItem dense disablePadding disableGutters>
    <ListItemText
      primary={<ChatLink snapshot={snapshot} chat={chat}/>}
      secondary={<TransRelTime ts={chat.updated_at}/>}/>
  </ListItem>
}

function ChatLink({snapshot, chat}: { snapshot: QueryDocumentSnapshot<CourseChat>, chat: CourseChat }) {
  return <Link to={GoSchool.courseChatView(snapshot.ref)} component={RouterLink}>
    {chat.title ?? 'New Chat'}
  </Link>
}

const StyledChatList = styled(List, {name: 'ChatList'})(
  ({theme}) => ({
    '& .MuiListItem-root': {
      '& .MuiListItemText-root': {
        margin: theme.spacing(0, 0),
        flexDirection: 'row',
        display: 'flex',
        justifyContent: 'stretch',
        gap: theme.spacing(1),
        '& .MuiListItemText-primary, & .MuiListItemText-secondary': {
          margin: theme.spacing(0.5, 0),
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          maxWidth: '100%'
        },
        '& .MuiListItemText-primary': {
          flexGrow: 1,
          flexShrink: 1
        },
        '& .MuiListItemText-secondary': {
          flexShrink: 0,
          textAign: 'right'
        }
      }
    }
  })
)
