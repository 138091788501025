import {typeConverter, useFirestoreResults} from '@goschool/react-firebase'
import {useMemo} from 'react'
import type { QueryDocumentSnapshot} from 'firebase/firestore'
import {collection} from 'firebase/firestore'
import type {Course, CourseStudent} from '../Course'

export function useCourseStudents(courseSnapshot: QueryDocumentSnapshot<Course>, max?: number) {
  const students = useFirestoreResults(useMemo(
    () =>
      collection(courseSnapshot.ref, 'students')
        .withConverter(typeConverter<CourseStudent>()),
    [courseSnapshot]
  ))
  return students
}
