import {useFirestore, useFirestoreSnapshot} from '@goschool/react-firebase'
import {useMemo} from 'react'
import {doc} from 'firebase/firestore'
import {courseDocumentConverter} from '../converters'

export function useDocument(organizationId?: string, courseId?: string, documentId?: string) {
  const firestore = useFirestore()

  const ref = useMemo(
    () => {
      if (organizationId!=null && courseId!=null && documentId!=null) {
        return doc(firestore,
          'organizations', organizationId,
          'courses', courseId,
          'documents', documentId
        ).withConverter(courseDocumentConverter)
      }
      return null
    }, [organizationId, courseId, documentId, firestore]
  )

  return useFirestoreSnapshot(ref)
}
