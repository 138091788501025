import {typeConverter, useFirestore, useFirestoreResults} from '@goschool/react-firebase'
import {useMemo} from 'react'
import type {
  QueryCompositeFilterConstraint,
  QueryDocumentSnapshot,
  QueryFieldFilterConstraint
} from 'firebase/firestore'
import {and, collection, or, orderBy, query, Timestamp, where} from 'firebase/firestore'
import type {GoSchoolInvitation} from '../GoSchoolInvitation'
import type {Organization} from '../Organization'

export function useInvitations(
  organization: QueryDocumentSnapshot<Organization> | null | undefined,
  showExpired = true
) {
  const firestore = useFirestore()


  const invitations = useFirestoreResults(useMemo(
    () => {
      if (organization == null) {
        return null
      }
      const invitationsCollection = collection(firestore, 'invitations').withConverter(typeConverter<GoSchoolInvitation>())
      const constraints: (QueryCompositeFilterConstraint | QueryFieldFilterConstraint)[] = [
        where('organization', '==', organization?.ref)
      ]
      if (!showExpired) {
        constraints.push(or(
          where('expires_at', '==', null),
          where('expires_at', '>', Timestamp.now())
        ))
      }
      return query(
        invitationsCollection,
        and(...constraints),
        orderBy('created_at', 'desc')
      )
    }, [firestore, organization, showExpired]))
  return invitations
}


export function useAccepted(snapshot: QueryDocumentSnapshot<GoSchoolInvitation>) {
  const accepted = useFirestoreResults(useMemo(
    () => collection(snapshot.ref, 'acceptance'),
    [snapshot]
  ))
  return accepted
}
