import type {QueryDocumentSnapshot} from 'firebase/firestore'
import {updateDoc} from 'firebase/firestore'
import type {CourseDocument} from '@goschool/model'
import {GoSchoolDialog} from '@goschool/mui'
import {type ComponentProps, type FormEvent, useCallback, useEffect, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material'
import {FirebaseError} from 'firebase/app'

interface DocumentUploadFormProps extends ComponentProps<typeof GoSchoolDialog> {
  close: () => void;
  documentSnapshot: QueryDocumentSnapshot<CourseDocument>;
}


export function DocumentEditDialog({documentSnapshot, close, onClose, ...dialogProps}: DocumentUploadFormProps) {
  const {t} = useTranslation()

  const [document, setDocument] = useState(documentSnapshot.data())

  useEffect(() => {
    setDocument(documentSnapshot.data())
  }, [documentSnapshot])

  const [fieldErrors, setFieldErrors] =
      useState<Partial<Record<keyof CourseDocument, string>>>({})

  const setField = useCallback(
      <F extends keyof CourseDocument>(field: F, value: CourseDocument[F]) => {
        setDocument((doc) => ({...doc, [field]: value}))
      },
      []
  )

  const setAuthors = useCallback(
    (authors: string) => {
      setField('authors', authors.split(','))
    },
    [setField]
  )

  const [submitting, setSubmitting] = useState<boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const submit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      if (submitting) {
        throw new Error('Cannot submit while submitting')
      }

      setSubmitting(true)
      setError(null)
      try {
        await updateDoc(documentSnapshot.ref, {
          ...document
        })
        setSubmitting(false)
        close()
      } catch (e) {
        if (e instanceof FirebaseError) {
          setError(e.message)
        } else {
          setError(t('document:forms.edit.errors.unknown'))
        }
        setSubmitting(false)
      }
    }, [document, documentSnapshot, submitting, t, close]
  )

  return <form onSubmit={submit}>
    <GoSchoolDialog {...dialogProps} onClose={onClose} fullWidth={true} maxWidth="md">
      <DialogTitle><Trans i18nKey="document:forms.edit.title"/></DialogTitle>
      <DialogContent>
        <TextField
          size="small" fullWidth={true} margin="normal"
          error={fieldErrors.title != null} helperText={fieldErrors.title}
          label={t('document:forms.edit.fields.title')} value={document.title}
          onChange={e => setField('title', e.target.value)}/>

        <TextField
          size="small" fullWidth={true} margin="normal"
          error={fieldErrors.subtitle != null} helperText={fieldErrors.subtitle}
          label={t('document:forms.edit.fields.subtitle')} value={document.subtitle}
          onChange={e => setField('subtitle', e.target.value)}/>

        <TextField
          size="small" fullWidth={true} margin="normal"
          error={fieldErrors.authors != null} helperText={fieldErrors.authors}
          label={t('document:forms.edit.fields.authors')} value={document.authors?.join(',') ?? ''}
          onChange={e => setAuthors(e.target.value)}/>

        <FormControl size="small" fullWidth={true} margin="normal">
          <InputLabel id="availability-label"><Trans i18nKey="document:forms.edit.fields.availability"/></InputLabel>
          <Select
            label={t('document:forms.edit.fields.availability')}
            error={fieldErrors.availability != null}
            value={document.availability ?? 'quote'}
            onChange={(e) => setField('availability',
                  e.target.value as CourseDocument['availability'])
            }>
            {['quote', 'public', 'read', 'private'].map((option) =>
              <MenuItem key={option} value={option}>
                {t(`document:forms.edit.availability.${option}`)}
              </MenuItem>
            )}
          </Select>
          <FormHelperText error={fieldErrors.availability != null}>{fieldErrors.availability}</FormHelperText>
        </FormControl>

        <TextField
          size="small" fullWidth={true} margin="normal" multiline={true} rows={3}
          error={fieldErrors.lead != null} helperText={fieldErrors.lead}
          label={t('document:forms.edit.fields.lead')} value={document.lead}
          onChange={e => setField('lead', e.target.value)}/>

        <TextField
          size="small" fullWidth={true} margin="normal" multiline={true} rows={5}
          error={fieldErrors.abstract != null} helperText={fieldErrors.abstract}
          label={t('document:forms.edit.fields.abstract')} value={document.abstract}
          onChange={e => setField('abstract', e.target.value)}/>

        {error != null &&
            <FormHelperText error={true}>{error}</FormHelperText>}
      </DialogContent>
      <DialogActions>
        <Button loading={submitting} type="submit" variant="contained" color="primary" autoFocus={true}
          onClick={submit}>
          <Trans i18nKey="document:forms.edit.submit"/>
        </Button>
        <Button type="reset" variant="outlined" color="primary" onClick={() => close()}>
          <Trans i18nKey="document:forms.edit.cancel"/>
        </Button>
      </DialogActions>
    </GoSchoolDialog>
  </form>
}
