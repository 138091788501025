import type {ChangeEvent} from 'react'
import {useCallback, useMemo, useReducer} from 'react'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  LinearProgress,
  List,
  ListItem,
  ListItemText,
  styled,
  Typography
} from '@mui/material'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'

import {Trans} from 'react-i18next'
import {getStorage} from 'firebase/storage'
import {uploadBatch, uploadReducer} from './fileUpload'
import {addDoc, collection} from 'firebase/firestore'
import {courseDocumentConverter, documentUploadConverter} from '@goschool/model'
import {useUserContext} from '@goschool/auth'
import {GoSchoolDialog} from '@goschool/mui'
import {useCourseContext} from '@goschool/dao'
import {useFirebaseAnalytics} from '@goschool/react-firebase'
import {logEvent} from 'firebase/analytics'

interface DocumentUploadFormProps {
  displayed: boolean;
  hide: () => void;
}


export function DocumentUploadDialog({displayed, hide}: DocumentUploadFormProps) {
  const {courseSnapshot} = useCourseContext()
  const fileTypes = useMemo(() => mimeTypes.join(','), [])
  const [state, dispatch] = useReducer(uploadReducer, {})
  const {user} = useUserContext()

  const [uploadsCollection, uploadsDirectory] = useMemo(
    () => {
      if (courseSnapshot != null) {
        return [
          collection(courseSnapshot.ref, 'uploads').withConverter(documentUploadConverter),
          `${courseSnapshot.ref.path}/uploads`
        ]
      }
      return [undefined, undefined]
    },
    [courseSnapshot]
  )

  const documentsCollection = useMemo(
    () => {
      if (courseSnapshot != null) {
        return collection(courseSnapshot.ref, 'documents').withConverter(courseDocumentConverter)
      }
      return undefined
    },
    [courseSnapshot]
  )
  const {analytics} = useFirebaseAnalytics()

  const upload = useCallback(
    async () => {
      const isReady = uploadsCollection != null && uploadsDirectory != null && documentsCollection != null
      const files = state.files
      const filesSelected = files != null && files.length !== 0
      const isIdle = state.uploads == null
      if (isReady && filesSelected && files != null && isIdle) {
        const storage = getStorage()
        const fileResults = await uploadBatch(files, uploadsCollection, uploadsDirectory, dispatch, storage)
        for (const result of fileResults) {
          if (result != null) {
            const [uploadRef, file] = result
            const documentReference = await addDoc(documentsCollection, {
              title: file.name,
              upload: uploadRef,
              uploader: user?.uid ?? null
            })
            logEvent(analytics, 'upload_document', {
              course_id: courseSnapshot?.id,
              document_id: documentReference.id
            })
          }
        }

        if (fileResults.every(r => r != null)) {
          dispatch({type: 'reset'})
          hide()
        }
      }
    },
    [documentsCollection, hide, state.files, state.uploads, uploadsCollection, uploadsDirectory, user?.uid, analytics, courseSnapshot]
  )

  const isFinished = useMemo(
    () => state.uploads != null && state.uploads.every(u => ['succeeded', 'failed'].includes(u.state)),
    [state]
  )

  const isUploading = useMemo(
    () => state.uploads != null && !isFinished,
    [state, isFinished]
  )

  const setFiles = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => dispatch({type: 'select', files: event.target.files ?? new FileList()}),
    [dispatch]
  )

  return <GoSchoolDialog open={displayed} PaperProps={{
    sx: {
      height: 600
    }
  }} maxWidth="lg" fullWidth={true} onClose={hide}>
    <DialogTitle><Trans i18nKey="document:forms.upload.title"/></DialogTitle>
    <DialogContent sx={{flex: '1 1 auto'}}>
      <Typography marginBottom={2}><Trans i18nKey="document:forms.upload.help"/></Typography>
      <Button
        disabled={isUploading || isFinished}
        sx={{flexShrink: 0, flexGrow: 0, mb: 2}}
        component="label" variant="contained" tabIndex={-1}
        role={undefined}
        startIcon={<CloudUploadIcon/>}>
        <Trans i18nKey="document:forms.upload.select-file"/>
        <VisuallyHiddenInput type="file" accept={fileTypes} multiple={true} onChange={setFiles}/>
      </Button>
      {
        state.files != null && <List dense={true} disablePadding={true}>{Array.from(state.files).map(
          (file, index) => {
            const uploadState = state.uploads?.[index]
            const secondary =
                    uploadState == null
                      ? <LinearProgress variant="determinate" value={0}/>
                      : 'progress' in uploadState
                        ? <LinearProgress
                          color={uploadState.state === 'succeeded' ? 'success' : uploadState.state === 'failed'
                            ? 'error'
                            : undefined}
                          variant="determinate" value={uploadState.progress}/>
                        : <LinearProgress variant="indeterminate"/>

            return <ListItem dense={true} disablePadding={true} key={index}>
              <ListItemText primary={file.name} secondary={secondary}/>
            </ListItem>
          }
        )}</List>

      }
    </DialogContent>
    <DialogActions sx={{mb: 3, mx: 3}}>
      <Button
        disabled={state.files == null || state.files.length === 0}
        loading={isUploading}
        variant="contained" color="primary" onClick={upload}><Trans
          i18nKey="document:forms.upload.upload"/></Button>
      <Button variant="outlined" disabled={isUploading} onClick={hide}><Trans
        i18nKey="document:forms.upload.cancel"/></Button>
    </DialogActions>
  </GoSchoolDialog>
}


const mimeTypes: string[] = [
  // 'text/plain', // Plain text files
  'application/pdf', // PDF documents
  // 'application/msword', // Microsoft Word .doc
  // 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', // Microsoft Word .docx

  // 'application/vnd.ms-powerpoint', // Microsoft PowerPoint .ppt
  // 'application/vnd.openxmlformats-officedocument.presentationml.presentation', // Microsoft PowerPoint .pptx

  'audio/mpeg', // MP3 audio
  'audio/aac', // AAC audio
  'audio/wav',
  'audio/x-wav', // WAV audio
  'audio/mp4',
  'audio/x-m4a', // M4A audio
  'audio/ogg', // OGG audio
  'audio/3gpp', // 3GPP audio, if it doesn't contain video
  'audio/amr', // AMR audio

  'video/x-msvideo',
  'video/mp4'
]

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1, overflow: 'hidden',
  position: 'absolute', bottom: 0,
  left: 0, whiteSpace: 'nowrap', width: 1
})

