import {createTheme, responsiveFontSizes} from '@mui/material'


import {typography} from './typography'
import {darkPalette, lightPalette} from './palette'
import {themeComponents} from './components'


const goSchoolDarkTheme = createTheme({
  palette: darkPalette,
  typography
})

const goSchoolLightTheme = createTheme({
  palette: lightPalette,
  typography
})

const paletteTheme = goSchoolLightTheme
export const goSchoolTheme = responsiveFontSizes(createTheme(
  paletteTheme,
  {
    shape: {},
    components: themeComponents(paletteTheme)
  }
))

