import {typeConverter, useFirestore, useFirestoreSnapshot} from '@goschool/react-firebase'
import {useMemo} from 'react'
import {doc} from 'firebase/firestore'
import type {GoSchoolUser} from '../GoSchoolUser'
import type {User} from 'firebase/auth'

export function useGoSchoolUser(user: User | null | undefined) {

  const firestore = useFirestore()

  const userRef = useMemo(
    () => {
      if (user===undefined) {
        return undefined
      }
      if (user===null) {
        return null
      }
      return doc(firestore, 'users', user.uid).withConverter(typeConverter<GoSchoolUser>())
    }, [user, firestore]
  )

  const userSnapshot = useFirestoreSnapshot(userRef, {waitForWrites: true})
  return userSnapshot

}
