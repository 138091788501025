import type {IconButtonProps} from '@mui/material'
import {IconButton, Stack} from '@mui/material'
import {Link as RouterLink} from 'react-router-dom'
import {GoSchool} from '@goschool/routing'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'
import {useCourseContext} from '@goschool/dao'
import {Authorize} from '@goschool/auth'
import type {SvgIconComponent} from '@mui/icons-material'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import GroupOutlinedIcon from '@mui/icons-material/GroupOutlined'
import {useCallback, useState} from 'react'
import {LinkInvitationDialog, QRInvitationDialog, useCourseInvitationGenerator} from './CourseInvitation'
import {EditCourseDialog} from './EditCourseDialog'
import ShareIcon from '@mui/icons-material/Share'
import QrCodeIcon from '@mui/icons-material/QrCode'
import EditIcon from '@mui/icons-material/Edit'
import type {GoSchoolUserRole} from '@goschool/model'

export function CourseActions() {
  const {courseSnapshot} = useCourseContext()

  return <>
    <Stack direction="row" sx={{minHeight: '26px'}}>
      <InstructorActionLink edge="start" to={GoSchool.courseDocuments(courseSnapshot.ref)} Icon={MenuBookIcon}/>
      <InstructorActionLink to={GoSchool.courseStudents(courseSnapshot.ref)} Icon={GroupOutlinedIcon}/>
      <InstructorActionLink edge="end" to={GoSchool.courseChats(courseSnapshot.ref)} Icon={ForumOutlinedIcon}/>
    </Stack>
    <Stack direction="row" marginLeft="auto">
      <InviteStudents/>
      <InviteQR/>
      <EditCourse/>
    </Stack>
  </>
}


export function InviteStudents() {
  const {invitation, isCreating, reset, createInvitation} = useCourseInvitationGenerator(72)

  return <InstructorActionButton Icon={ShareIcon} onClick={createInvitation} disabled={isCreating}>
    <LinkInvitationDialog invitation={invitation} isCreating={isCreating} hide={reset}/>
  </InstructorActionButton>
}


export function InviteQR() {
  const {invitation, isCreating, reset, createInvitation} = useCourseInvitationGenerator(72)

  return <InstructorActionButton Icon={QrCodeIcon} onClick={createInvitation} disabled={isCreating}>
    <QRInvitationDialog invitation={invitation} isCreating={isCreating} hide={reset}/>
  </InstructorActionButton>
}

export function EditCourse() {
  const [editing, setEditing] = useState(false)
  const edit = useCallback(() => setEditing(true), [setEditing])
  const reset = useCallback(() => setEditing(false), [setEditing])

  return <InstructorActionButton Icon={EditIcon} onClick={edit} disabled={editing}>
    <EditCourseDialog displayed={editing} hide={reset}/>
  </InstructorActionButton>
}


interface CourseActionLinkProps extends Omit<IconButtonProps, 'size'> {
  to: string,
  Icon: SvgIconComponent
  roles: GoSchoolUserRole[]
}

export function CourseActionLink({to, Icon, roles, ...buttonProps}: CourseActionLinkProps) {
  const {courseRoles} = useCourseContext()
  return <Authorize roles={roles} contextRoles={courseRoles}>
    <IconButton size="small" component={RouterLink} to={to} {...buttonProps}>
      <Icon sx={{fontSize: '16px'}}/>
    </IconButton>
  </Authorize>
}

type InstructorActionLinkProps = Omit<CourseActionLinkProps, 'roles'>

export function InstructorActionLink({to, Icon, ...buttonProps}: InstructorActionLinkProps) {
  return <CourseActionLink to={to} Icon={Icon} roles={['instructor']} {...buttonProps}/>
}

type StudentActionLinkProps = Omit<CourseActionLinkProps, 'roles'>

export function StudentActionLink({to, Icon, ...buttonProps}: StudentActionLinkProps) {
  return <CourseActionLink to={to} Icon={Icon} roles={['student']} {...buttonProps}/>
}


interface InstructorActionButtonProps extends Omit<IconButtonProps, 'size'> {
  Icon: SvgIconComponent;
}

function InstructorActionButton({Icon, children, ...buttonProps}: InstructorActionButtonProps) {
  const {courseRoles} = useCourseContext()
  return <Authorize roles={['instructor', 'admin']} contextRoles={courseRoles}>
    <IconButton size="small" component={RouterLink}  {...buttonProps}>
      <Icon sx={{fontSize: '16px'}}/>
    </IconButton>
    {children}
  </Authorize>
}
