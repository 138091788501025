import type {LinkProps} from '@mui/material/Link'
import {LinkBehavior} from '@goschool/mui'
import type {Theme} from '@mui/material'

export function themeComponents(baseTheme: Theme) {
  return {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior
      } as LinkProps
    },
    MuiButtonBase: {
      defaultProps: {
        LinkComponent: LinkBehavior
      }
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          '& .MuiIconButton-root': {
            color: baseTheme.palette.primary.contrastText
          }
        }
      }
    },
    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          '& .MuiBreadcrumbs-ol': {
            '& .MuiBreadcrumbs-li': {
              '& .MuiTypography-root': {
                color: baseTheme.palette.primary.contrastText
              }
            }
          },
          '& li.MuiBreadcrumbs-separator': {
            color: baseTheme.palette.primary.contrastText
          }
        }
      }
    }
  } as const
}
