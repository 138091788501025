import { styled, Typography } from '@mui/material'
import { Trans, useTranslation } from 'react-i18next'
import { Section } from './Scroller'

export function Manifesto() {
  const { t } = useTranslation()
  const manifesto: string[] = t('landing:manifesto', { returnObjects: true })

  return <Section>
    <Typography variant="h4" marginBottom={4} gutterBottom={true} component="p"><Trans i18nKey="landing:subheadline" /></Typography>
    <Typography variant="h6">
      {
        manifesto.map((item: string, index: number) =>
          <span key={index}>
            <Trans
              i18nKey={`landing:manifesto.${index}`}
              components={{
                1: <ManifestoHighlight />
              }}
            />
            {index < manifesto.length - 1 && <ManifestoSeparator>&nbsp;&bull;&nbsp;</ManifestoSeparator>}
          </span>
        )
      }
    </Typography>
  </Section>
}


const ManifestoHighlight = styled('span', {
  name: 'ManifestoHighlight',
  slot: 'Root'
})(({ theme }) => ({
  fontWeight: 'bold',
  fontSize: '1.3em'
}))

const ManifestoSeparator = styled('span', {
  name: 'ManifestoSeparator',
  slot: 'Root'
})(({ theme }) => ({
  fontSize: '1.3em'
}))
