import type {CardProps} from '@mui/material'
import {Card, CardContent, CardHeader, styled} from '@mui/material'
import type {QueryDocumentSnapshot} from 'firebase/firestore'
import type {Chat} from '@goschool/model'
import type {ReactNode} from 'react'
import {CourseChatsList} from './CourseChatList'

export interface CourseChatsCardProps extends CardProps {
  chats: QueryDocumentSnapshot<Chat>[] | null | undefined
  noChatsAction?: ReactNode
  title: string
  count?: number
}

export function CourseChatsCard({chats, noChatsAction, title, count, ...cardProps}: CourseChatsCardProps) {
  return <StyledCourseChatCard {...cardProps}>
    <CardHeader title={title} subheader={count ? count : undefined}/>
    <CardContent>
      <CourseChatsList chats={chats} noChatsAction={noChatsAction}/>
    </CardContent>
  </StyledCourseChatCard>
}

const StyledCourseChatCard = styled(Card, {
  name: 'CourseChatsCard'
})(({theme}) => ({
  '& .MuiCardHeader-root': {
    padding: theme.spacing(1, 2, 0)
  },
  '& .MuiCardContent-root': {
    padding: theme.spacing(0, 2, 1)
  }
}))
