import type {QueryConstraint, QueryDocumentSnapshot} from 'firebase/firestore'
import {collection, collectionGroup, doc, getCountFromServer, limit, orderBy, query, where} from 'firebase/firestore'
import type {Course} from '../Course'
import {typeConverter, useFirestore, useFirestoreResults, useFirestoreSnapshot} from '@goschool/react-firebase'
import {useEffect, useMemo, useState} from 'react'
import type {CourseChat} from '../CourseChat'
import type {User} from 'firebase/auth'
import type {GoSchoolUser} from '../GoSchoolUser'
import type {Organization} from '../Organization'
import type {Chat} from '../Chat'
import {courseChatConverter} from '../converters'


export function useChat(organizationId?: string, courseId?: string, chatId?: string) {
  const firestore = useFirestore()

  const ref = useMemo(
    () => {
      if (organizationId != null && courseId != null && chatId != null) {
        return doc(firestore,
          'organizations', organizationId,
          'courses', courseId,
          'chats', chatId
        ).withConverter(courseChatConverter)
      }
      return null
    }, [organizationId, courseId, chatId, firestore]
  )

  return useFirestoreSnapshot(ref)
}


export function useUserCourseChats(courseSnapshot: QueryDocumentSnapshot<Course>, user: User | undefined | null, max?: number) {
  const collectionRef = useMemo(
    () =>
      collection(courseSnapshot.ref, 'chats').withConverter(typeConverter<CourseChat>()),
    [courseSnapshot]
  )


  const q = useMemo(
    () => {
      if (user != null) {
        const constraints: QueryConstraint[] = [
          where('participants', 'array-contains', user.uid),
          orderBy('updated_at', 'desc')
        ]
        if (max) {
          constraints.push(limit(max))
        }
        return query(
          collectionRef,
          ...constraints
        )
      }
      return null
    },
    [collectionRef, max, user]
  )
  return useFirestoreResults(q, {waitForWrites: true})
}


export function useCourseChats(courseSnapshot: QueryDocumentSnapshot<Course>, max?: number) {
  return useFirestoreResults(useMemo(
    () => {
      const constraints: QueryConstraint[] = [orderBy('updated_at', 'desc')]
      if (max != null) {
        constraints.push(limit(max))
      }

      return query(
        collection(courseSnapshot.ref, 'chats').withConverter(typeConverter<CourseChat>()),
        ...constraints
      )
    },
    [courseSnapshot, max]
  ))
}

export function useNumberOfCourseChats(courseSnapshot: QueryDocumentSnapshot<Course>) {
  const [result, setResult] = useState<number | null>()

  useEffect(
    () => {
      getCountFromServer(
        collection(courseSnapshot.ref, 'chats').withConverter(typeConverter<CourseChat>())
      ).then(x => setResult(x.data().count))
      return () => setResult(undefined)
    },
    [courseSnapshot]
  )
  return result
}


export function useAllChats() {
  const firestore = useFirestore()

  return useFirestoreResults(useMemo(
    () =>
      query(
        collectionGroup(firestore, 'chats').withConverter(typeConverter<CourseChat>()),
        orderBy('updated_at', 'desc')
      ),
    [firestore]
  ))
}


export function useChatCreatedBy(chat: Chat) {
  const firestore = useFirestore()
  const userSnapshot = useFirestoreSnapshot(useMemo(
    () => {
      if (chat.created_by == null) {
        return null
      }
      if (chat.anonymous ?? true) {
        return null
      }

      return doc(firestore, 'users', chat.created_by).withConverter(typeConverter<GoSchoolUser>())

    },
    [chat, firestore]
  ), {watch: false})
  return userSnapshot
}

export function useChatOrganization(snapshot: QueryDocumentSnapshot<CourseChat>) {
  const ref = useMemo(
    () => snapshot.ref.parent.parent?.parent.parent?.withConverter(typeConverter<Organization>()),
    [snapshot]
  )
  const orgSnapshot = useFirestoreSnapshot(ref, {watch: false})

  return orgSnapshot

}

export function useChatCourse(snapshot: QueryDocumentSnapshot<CourseChat>) {
  const ref = useMemo(
    () => snapshot.ref.parent.parent?.withConverter(typeConverter<Course>()),
    [snapshot]
  )
  return useFirestoreSnapshot(ref, {watch: false})
}
