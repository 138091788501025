import type {CollectionReference, Query, QuerySnapshot, Unsubscribe} from 'firebase/firestore'
import {getDocs, onSnapshot} from 'firebase/firestore'
import {InternalError, PermissionDenied} from '../errors'
import {useEffect, useState} from 'react'
import type {FirestoreResultsOptions} from './useFirestoreSnapshot'
import {defaultOptions} from './useFirestoreSnapshot'
import {EventEmitter} from '../util'
import {FirebaseError} from 'firebase/app'
import type {QueryDocumentSnapshot} from '@firebase/firestore'

type ResponseType<T> = [
  snapshot: QueryDocumentSnapshot<T>[] | null | undefined,
  error: Error | null | undefined
];

export function useFirestoreResults<T>(
  query: Query<T> | CollectionReference<T> | undefined | null,
  options?: Partial<FirestoreResultsOptions>
): QueryDocumentSnapshot<T>[] | null | undefined {
  const [results, error] = useFirestoreResultsWithError(query, options)
  if (error) {
    throw error
  }
  return results
}

export function useFirestoreResultsWithError<T>(
  query: Query<T> | CollectionReference<T> | undefined | null,
  options?: Partial<FirestoreResultsOptions>
): ResponseType<T> {
  const [result, setResult] = useState<QueryDocumentSnapshot<T>[] | null>()
  const [error, setError] = useState<Error | null>()

  useEffect(() => {
    const manager = new FirestoreQueryManager(query, {
      waitForWrites: options?.waitForWrites ?? defaultOptions.waitForWrites,
      watch: options?.watch ?? defaultOptions.watch
    })

    const handleUpdate = (snapshot: QuerySnapshot<T>) => {
      setResult(snapshot.docs)
      setError(null)
    }

    const handleFailure = (error: Error) => {
      setError(error)
      setResult(undefined)
    }

    manager.addEventListener('updated', handleUpdate)
    manager.addEventListener('failed', handleFailure)

    return () => {
      manager.removeEventListener('updated', handleUpdate)
      manager.removeEventListener('failed', handleFailure)
      manager.close()
    }
  }, [query, options?.watch, options?.waitForWrites])

  return [result, error]
}

interface FirestoreQueryEvents<T> {
  subscribed: undefined;
  closed: undefined;
  fetching: undefined;
  updated: QuerySnapshot<T>;
  failed: Error;
}

export class FirestoreQueryManager<T> extends EventEmitter<
  FirestoreQueryEvents<T>
> {
  private _snapshot: QuerySnapshot<T> | null | undefined
  private _error: Error | null | undefined
  private _unsubscribe: Unsubscribe | undefined
  private _closed = false

  constructor(
    private query: Query<T> | CollectionReference<T> | undefined | null,
    private options?: Partial<FirestoreResultsOptions>
  ) {
    super()
    if (query === null) {
      this._snapshot = null
      this._error = null
    } else if (query != null) {
      if (options?.watch ?? defaultOptions.watch) {
        this.subscribe()
        this.emit('subscribed', undefined)
      } else {
        this.emit('fetching', undefined)
        this.fetch()
      }
    }
  }

  close() {
    if (this._unsubscribe) {
      this._unsubscribe()
      this._unsubscribe = undefined
      this._closed = true
      this.emit('closed', undefined)
    }
  }

  private async fetch() {
    if (this.query === undefined) {
      this._snapshot = undefined
      this._error = null
    } else if (this.query == null) {
      this._snapshot = null
      this._error = null
    } else {
      try {
        this.emit('fetching', undefined)
        this._snapshot = await getDocs(this.query)
        this.emit('updated', this._snapshot)
      } catch (error) {
        this.handleError(error)
      }
    }
  }

  private subscribe() {
    if (this.query == null) {
      throw new Error('ref is null')
    }
    const waitForWrites =
      this.options?.waitForWrites ?? defaultOptions.waitForWrites
    try {
      this._unsubscribe = onSnapshot(
        this.query,
        {includeMetadataChanges: true},
        (snapshot) => {
          if (!waitForWrites || !snapshot.metadata.hasPendingWrites) {
            this._snapshot = snapshot
            this.emit('updated', snapshot)
          }
        },
        this.handleError
      )
    } catch (error) {
      this.handleError(error)
    }
  }

  private handleError = (error: unknown) => {
    this._snapshot = undefined
    if (error instanceof FirebaseError) {
      if (error.code === 'permission-denied') {
        this._error = new PermissionDenied(error)
      } else {
        this._error = new InternalError(error)
      }
    } else {
      this._error = new InternalError()
    }
    this.emit('failed', this._error)
  }
}
