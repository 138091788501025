import {FormControl, InputLabel, MenuItem, Select} from '@mui/material'
import {Trans, useTranslation} from 'react-i18next'

export const availableLanguages = [
  'hu', 'en', 'ro'
]

export interface LanguageSelectorProps {
  languageCode: string
  setLanguageCode: (languageCode: string) => void
  label: string
}

export function LanguageSelector({languageCode, setLanguageCode, label}: LanguageSelectorProps) {
  const {t} = useTranslation()


  return <FormControl fullWidth size="small" margin="normal">
    <InputLabel size="small" shrink={true} id="language-label">{label}</InputLabel>
    <Select
      size="small" notched={true} fullWidth={true}
      label={label} labelId="language-label"
      value={languageCode}
      onChange={(event) => setLanguageCode(event.target.value as string)}>{
        availableLanguages.map((language) =>
          <MenuItem key={language} value={language} title={t(language, {ns: 'languages'})}><Trans
            i18nKey={language} ns="languages"/></MenuItem>
        )
      }
    </Select>
  </FormControl>
}
