import {Box, Skeleton, Typography} from '@mui/material'
import type {QueryDocumentSnapshot} from 'firebase/firestore'
import type {Course} from '@goschool/model'
import {useMemo} from 'react'
import {useInstructorNames} from '@goschool/dao'

export function CoursePageHeader({courseSnapshot}: { courseSnapshot: QueryDocumentSnapshot<Course> }) {
  const course = useMemo(
    () => courseSnapshot.data(),
    [courseSnapshot]
  )

  const instructorNames = useInstructorNames(courseSnapshot)

  return <Box flexGrow={0}>
    <Typography variant="h4" gutterBottom={false} component="h1">{course?.title ?? <Skeleton/>}&nbsp;
      <Typography variant="caption">{course?.code ?? <Skeleton/>}</Typography></Typography>
    <Typography variant="subtitle1">{instructorNames ?? <Skeleton/>}</Typography>
  </Box>
}
