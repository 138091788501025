import {Skeleton, Stack, Typography} from '@mui/material'
import {FixedFab, PageLayout} from '@goschool/components'
import {useParams} from 'react-router-dom'
import {NotFound} from '@goschool/react-firebase'
import {useCallback, useMemo, useState} from 'react'
import {PdfViewer} from './components/PdfViewer'
import {DocumentEditDialog} from './components/DocumentEditDialog'
import type {QueryDocumentSnapshot} from 'firebase/firestore'
import type {Course, CourseDocument, GoSchoolUserRole} from '@goschool/model'
import {useCourse, useDocument} from '@goschool/model'
import EditIcon from '@mui/icons-material/Edit'
import {useUserContext} from '@goschool/auth'
import {DocumentState, useDocumentState} from './components/CourseDocumentList'
import {GoSchool} from '@goschool/routing'
import {useCourseDocumentsPageBreadcrumb} from '../course'


export function useDocumentBreadcrumbs(
  documentSnapshot: QueryDocumentSnapshot<CourseDocument>,
  courseSnapshot: QueryDocumentSnapshot<Course>) {
  const documentsBreadcrumbs = useCourseDocumentsPageBreadcrumb(courseSnapshot)

  if (!documentSnapshot.exists()) {
    return []
  }
  return [
    ...documentsBreadcrumbs,
    {
      title: documentSnapshot.data().title,
      href: GoSchool.document(documentSnapshot.ref)
    }
  ]

}


export function CourseDocumentPage() {
  const {organizationId, courseId, documentId} = useParams<{
    organizationId: string
    courseId: string
    documentId: string
  }>()

  const documentSnapshot = useDocument(organizationId, courseId, documentId)
  const courseSnapshot = useCourse(organizationId, courseId)


  if (documentSnapshot === undefined || courseSnapshot === undefined) {
    return null
  }

  if (!documentSnapshot?.exists() || !courseSnapshot?.exists()) {
    throw new NotFound()
  }

  return <CourseDocumentPageContent courseSnapshot={courseSnapshot} documentSnapshot={documentSnapshot}/>
}


function CourseDocumentPageContent({documentSnapshot, courseSnapshot}: {
  documentSnapshot: QueryDocumentSnapshot<CourseDocument>,
  courseSnapshot: QueryDocumentSnapshot<Course>
}) {
  const document = useMemo(
    () => documentSnapshot.data(),
    [documentSnapshot]
  )
  const {roles} = useUserContext()

  const state = useDocumentState(document)
  const [editing, setEditing] = useState(false)
  const showEditor = useCallback(
    () => setEditing(true),
    []
  )
  const hideEditor = useCallback(
    () => setEditing(false),
    []
  )


  return <PageLayout
    fullScreen={false}
    breadcrumbs={useDocumentBreadcrumbs(documentSnapshot, courseSnapshot)}>
    <Stack>
      <Typography variant="h4" component="h1">{document.title ?? <Skeleton/>}</Typography>
      <Typography variant="subtitle1">{document.subtitle}</Typography>
      <DocumentState state={state}/>
      <Typography variant="body1">{document.authors?.join(', ') ?? <Skeleton/>}</Typography>
      <Typography variant="caption">{document.abstract ?? <Skeleton/>}</Typography>
    </Stack>
    <DocumentView document={document} roles={roles}/>
    <DocumentEditDialog documentSnapshot={documentSnapshot} open={editing} onClose={hideEditor} close={hideEditor}/>
    <FixedFab onClick={showEditor} horizontal="center" vertical="bottom" color="primary">
      <EditIcon/>
    </FixedFab>
  </PageLayout>
}


function DocumentView({document, roles}: { document: CourseDocument, roles: GoSchoolUserRole[] }) {
  const allowed = roles.includes('instructor') || roles.includes('admin') || document.availability === 'public'
  if (!allowed) {
    return null
  }

  if (document.content_type === 'application/pdf') {
    return <PdfViewer document={document}/>
  }
}
