import {Box, FormControlLabel, Stack, Switch} from '@mui/material'
import type {PageReference} from '@goschool/components'
import {LoadingPage, PageLayout} from '@goschool/components'
import {useParams} from 'react-router-dom'
import {NotFound} from '@goschool/react-firebase'
import {CourseContextProvider, useCourseContext, useInstructorNames} from '@goschool/dao'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {GoSchool} from '@goschool/routing'
import type {DocumentSnapshot} from 'firebase/firestore'
import type {Course, GoSchoolUserRole} from '@goschool/model'
import {useCourse} from '@goschool/model'
import {InstructorCourse, StudentCourse} from './course'
import {useTranslation} from 'react-i18next'
import {CoursePageHeader} from './course/components/CoursePageHeader'


export function useCoursePageBreadcrumb(courseSnapshot: DocumentSnapshot<Course> | null | undefined): PageReference[] {
  if (!courseSnapshot?.exists()) {
    return []
  }
  return [{
    title: courseSnapshot.data().title,
    href: GoSchool.course(courseSnapshot.ref)
  }]
}

export function CoursePage() {
  const {organizationId, courseId} = useParams<{
    organizationId: string
    courseId: string
  }>()

  const courseSnapshot = useCourse(organizationId, courseId)


  const breadcrumbs = useCoursePageBreadcrumb(courseSnapshot)
  if (courseSnapshot == null) {
    return LoadingPage()
  }


  if (!courseSnapshot.exists()) {
    throw new NotFound()
  }

  return <PageLayout fullScreen={false} breadcrumbs={breadcrumbs}>
    <CourseContextProvider courseSnapshot={courseSnapshot}>
      <CoursePageContent/>
    </CourseContextProvider>
  </PageLayout>
}

function CoursePageContent() {
  const {courseRoles, courseSnapshot} = useCourseContext()
  const [selectedRole, setSelectedRole] = useState<GoSchoolUserRole>()
  useEffect(() => {
    const storedRole = localStorage.getItem('courses.selectedRole') as GoSchoolUserRole
    if (storedRole != null && courseRoles.includes(storedRole)) {
      setSelectedRole(storedRole)
    } else if (courseRoles.includes('instructor') || courseRoles.includes('admin')) {
      setSelectedRole('instructor')
    } else if (courseRoles.includes('student')) {
      setSelectedRole('student')
    }

  }, [courseRoles])

  const course = useMemo(
    () => courseSnapshot === null ? null : courseSnapshot?.data(),
    [courseSnapshot]
  )
  const instructorNames = useInstructorNames(courseSnapshot)


  const selectRole = useCallback(
    (role: GoSchoolUserRole) => {
      const availableRoles: GoSchoolUserRole[] = courseRoles.filter(r => r !== 'admin')
      if (courseRoles.includes('admin')) {
        availableRoles.push('instructor')
        availableRoles.push('student')
      }
      if (availableRoles.includes(role)) {
        setSelectedRole(role)
        localStorage.setItem('courses.selectedRole', role)
      }
    },
    [courseRoles]
  )


  return <>
    <Stack direction="row" gap={4} alignItems="center" justifyContent="stretch">
      <Box flexGrow={1} justifyContent={'flex-end'}><CoursePageHeader courseSnapshot={courseSnapshot}/></Box>
      <RoleSelector selectedRole={selectedRole} setRole={selectRole}/>
    </Stack>
    {selectedRole === 'instructor'
      ? <InstructorCourse snapshot={courseSnapshot}/>
      : selectedRole === 'student'
        ? <StudentCourse snapshot={courseSnapshot}/>
        : null
    }
  </>
}

function RoleSelector({selectedRole, setRole}: {
  selectedRole?: GoSchoolUserRole,
  setRole: (role: GoSchoolUserRole) => void
}) {
  const {courseRoles} = useCourseContext()
  const {t} = useTranslation()

  const isInstructor = useMemo(
    () => courseRoles.includes('instructor') || courseRoles.includes('admin'),
    [courseRoles]
  )

  if (isInstructor) {
    return <FormControlLabel
      labelPlacement="start"
      slotProps={{typography: {variant: 'caption'}}}
      control={<Switch
        checked={selectedRole === 'student'}
        onChange={e => setRole(e.target.checked ? 'student' : 'instructor')}/>}
      label={t('course:actions.studentView')}/>
  }
  return null
}
