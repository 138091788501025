import type {FormEvent} from 'react'
import {useCallback, useEffect, useState} from 'react'
import type {SvgIconProps} from '@mui/material'
import {IconButton, InputBase, Stack, styled, SvgIcon} from '@mui/material'
import {useTranslation} from 'react-i18next'
import {useChatContext} from './ChatContext'
import {useSentry} from '../util/useSentry'
import type {SubmitState} from '@goschool/model'
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward'

export function PromptForm() {
  const {chatManager} = useChatContext()
  const [message, setMessage] = useState<string>('')

  const {t} = useTranslation()
  const [submitState, setSubmitState] = useState<SubmitState>('idle')
  useEffect(() => {
    if (chatManager !== undefined) {
      chatManager.addEventListener('submitStateUpdate', setSubmitState)

      return () => {
        chatManager.removeEventListener('submitStateUpdate', setSubmitState)
      }
    }
  }, [chatManager])

  useEffect(() => {
    setMessage('')
  }, [chatManager])

  const reportError = useSentry()

  const sendMessage = useCallback(
    async (event: FormEvent) => {
      event.preventDefault()
      if (submitState === 'idle') {
        try {
          await chatManager.post(message)
          setMessage('')
        } catch (error) {
          reportError(error)
          console.error(error)
        }
      }
    },
    [chatManager, message, reportError, submitState]
  )

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter' && !event.shiftKey) {
      event.preventDefault()
      sendMessage(event)
    }
  }

  return <StyledPromptForm onSubmit={sendMessage}>
    <InputBase
      placeholder={t('chat:prompt.input-placeholder')}
      autoFocus={true}
      id="user_message" multiline maxRows={4} minRows={1}
      value={message} onKeyDown={handleKeyDown}
      fullWidth
      onChange={(e) => setMessage(e.target.value)}
    />
    <Stack className="promptActions">
      <IconButton
        type="submit"
        color="primary"
        size="small"
        disableRipple
        edge="end"
        onClick={sendMessage}
        disabled={submitState !== 'idle' || message === ''}>
        {['pending', 'generating'].includes(submitState) ? <WaveIcon/> : <ArrowUpwardIcon/>}
      </IconButton>
    </Stack>
  </StyledPromptForm>
}

const StyledPromptForm = styled('form')(
  ({theme}) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'stretch',
    justifyContent: 'stretch',
    '& .MuiInputBase-root': {
      fontSize: theme.typography.body2.fontSize
    },
    '& .promptActions': {
      flexDirection: 'row',
      justifyContent: 'flex-end',
      gap: theme.spacing(1),
      alignItems: 'center',
      '& button[type=submit]': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:disabled': {
          backgroundColor: theme.palette.action.disabledBackground,
          color: theme.palette.action.disabled
        }
      }
    }
  })
)

function WaveIcon(props: SvgIconProps) {
  return <SvgIcon viewBox="0 0 24 24" {...props}>
    <WaveLine x={3} y={6} start={0}/>
    <WaveLine x={7} y={6} start={0.2}/>
    <WaveLine x={11} y={6} start={0.4}/>
    <WaveLine x={15} y={6} start={0.6}/>
    <WaveLine x={15} y={6} start={0.8}/>
    <WaveLine x={19} y={6} start={1.0}/>
  </SvgIcon>
}

function WaveLine({x, y, start}: { x: number, y: number, start: number }) {
  return <rect x={x} y={y} width="2" height="12">
    <animate attributeName="height" values="4;16;4" dur="1.2s" repeatCount="indefinite" begin={`${start}s`}/>
    <animate attributeName="y" values="11;5;11" dur="1.2s" repeatCount="indefinite" begin={`${start}s`}/>
  </rect>
}
