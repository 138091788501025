import {useOrganizations} from '@goschool/model'
import {useCallback, useEffect, useMemo} from 'react'
import {useLocalStorage} from 'usehooks-ts'
import type {SelectChangeEvent} from '@mui/material'
import {MenuItem, Select, Stack} from '@mui/material'

export function OrganizationSelector() {
  const organizations = useOrganizations()
  const [value, setValue, removeValue] = useLocalStorage<string | undefined>('admin.organizationId', undefined)

  useEffect(() => {
    if (organizations != null && organizations.length > 0) {
      setValue((current) => current ?? organizations[0].id)
    }
  }, [organizations, setValue])

  const handleSelect = useCallback(
    async (event: SelectChangeEvent) => {
      const orgSnapshot = organizations?.find(o => o.id === event.target.value)
      if (orgSnapshot) {
        setValue(orgSnapshot.ref.id)
      } else {
        removeValue()
      }
    }, [organizations, removeValue, setValue]
  )

  if (organizations == null) {
    return null
  }
  return <Stack direction="row" gap={2}>
    <Select value={value ?? ''} onChange={handleSelect} size="small">
      {organizations?.map(org => <MenuItem dense key={org.id} value={org.id}>{org.data().name}</MenuItem>)}
    </Select>
  </Stack>
}

export function useSelectedOrganization() {
  const organizations = useOrganizations()
  const [organizationId] = useLocalStorage<string | undefined>('admin.organizationId', undefined)

  const organization = useMemo(
    () => {
      if (organizations == null) {
        return null
      }

      return organizations.find(o => o.id === organizationId) ?? organizations[0]
    },
    [organizations, organizationId]
  )

  return organization
}
