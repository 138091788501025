import {PageLayout} from '@goschool/components'
import {useNavigate, useParams} from 'react-router-dom'
import {NotFound, useFirebaseAnalytics} from '@goschool/react-firebase'
import {CourseContextProvider} from '@goschool/dao'
import {type FormEvent, useCallback, useMemo, useState} from 'react'
import {GoSchool} from '@goschool/routing'
import type {Course} from '@goschool/model'
import {useCourse} from '@goschool/model'
import {useCoursePageBreadcrumb} from '../CoursePage'
import {Trans, useTranslation} from 'react-i18next'
import {Button, FormHelperText, Stack, TextField, Typography} from '@mui/material'
import {LanguageSelector} from '../components/LanguageSelector'
import type {QueryDocumentSnapshot} from 'firebase/firestore'
import {updateDoc} from 'firebase/firestore'
import {logEvent} from 'firebase/analytics'
import {CoursePageHeader} from './components/CoursePageHeader'

interface CourseFields {
  title: string;
  code: string;
  description: string;
  language_code: string;
}

interface CourseFieldErrors {
  title?: string;
  code?: string;
  description?: string;
  language_code?: string;
}

export function EditCoursePage() {
  const {organizationId, courseId} = useParams<{
    organizationId: string;
    courseId: string;
  }>()

  const courseSnapshot = useCourse(organizationId, courseId)
  const course = useMemo(
    () => courseSnapshot === null ? null : courseSnapshot?.data(),
    [courseSnapshot]
  )
  const {t} = useTranslation()
  const courseBreadcrumbs = useCoursePageBreadcrumb(courseSnapshot)
  const breadcrumbs = useMemo(
    () => {
      if (!courseSnapshot?.exists()) {
        return []
      }
      return [
        ...courseBreadcrumbs,
        {
          title: t('course:actions.edit'),
          href: GoSchool.courseEdit(courseSnapshot.ref)
        }
      ]
    }, [courseBreadcrumbs, courseSnapshot, t]
  )

  const instructorNames = useMemo(
    () => {
      return course?.instructors?.map(i => i.name).join(', ')
    },
    [course]
  )

  if (courseSnapshot === undefined) {
    return null
  }

  if (!courseSnapshot?.exists() || course == null) {
    throw new NotFound()
  }

  return (
    <PageLayout
      fullScreen={false}
      breadcrumbs={breadcrumbs}>
      <CourseContextProvider courseSnapshot={courseSnapshot}>
        <CoursePageHeader courseSnapshot={courseSnapshot}/>
        <Typography variant="h5" component="h3" gutterBottom={true}><Trans
          i18nKey="course:students.title"/></Typography>
        <CourseEditForm snapshot={courseSnapshot}/>
      </CourseContextProvider>
    </PageLayout>
  )
}

type SubmitState = 'idle' | 'pending' | 'success' | 'failed';

function CourseEditForm({snapshot}: { snapshot: QueryDocumentSnapshot<Course> }) {
  const {title, code, description, language_code} = snapshot.data()

  const [fields, setFields] = useState<CourseFields>({
    title, code, description, language_code
  })

  const [fieldErrors, setFieldErrors] = useState<CourseFieldErrors>({})
  const [error, setError] = useState<string | null>(null)
  const [state, setState] = useState<SubmitState>('idle')

  const {t} = useTranslation()

  const setField = useCallback((key: keyof CourseFields, value: string) => {
    setFields(fields => ({...fields, [key]: value}))
  }, [])

  const navigate = useNavigate()
  const {analytics} = useFirebaseAnalytics()
  const cancel = useCallback(
    () => navigate(GoSchool.course(snapshot.ref)),
    [navigate, snapshot]
  )
  const submit = useCallback(
    async (e: FormEvent) => {
      e.preventDefault()
      if (state !== 'idle') {
        return
      }

      setState('pending')
      setError(null)
      setFieldErrors({})

      const {title, description, code, language_code} = fields

      try {
        await updateDoc(snapshot.ref, {
          title, description, code, language_code
        })
        setState('success')
        logEvent(analytics, 'update_course', {
          course_id: snapshot.ref.id
        })
        await navigate(GoSchool.course(snapshot.ref))
      } catch (e) {
        reportError(e)

        if (e instanceof Error) {
          setError(e.message)
        } else {
          setError('An unknown error occurred')
        }
        setState('failed')
      }
    }, [state, fields, snapshot.ref, analytics, navigate]
  )

  return <form onSubmit={submit}>
    <TextField
      size="small" fullWidth={true} margin="normal"
      label={t('course:forms.edit.fields.title')} value={fields.title ?? ''}
      error={fieldErrors.title != null} helperText={fieldErrors.title}
      onChange={e => setField('title', e.target.value)}/>

    <TextField
      size="small" fullWidth={true} margin="normal"
      label={t('course:forms.edit.fields.code')} value={fields.code ?? ''}
      error={fieldErrors.code != null} helperText={fieldErrors.code}
      onChange={e => setField('code', e.target.value)}/>

    <LanguageSelector languageCode={fields.language_code} setLanguageCode={
      (language) => setField('language_code', language)
    } label={t('course:forms.edit.fields.language')}/>

    <TextField
      size="small" fullWidth={true} multiline={true} margin="normal"
      minRows={5} maxRows={10}
      error={fieldErrors.description != null} helperText={fieldErrors.description}
      label={t('course:forms.edit.fields.description')} value={fields.description ?? ''}
      onChange={e => setField('description', e.target.value)}/>

    {error != null && <FormHelperText error={true}>{error}</FormHelperText>}

    <Stack direction="row" justifyContent="flex-end" gap={2} mt={2}>
      <Button sx={{flexShrink: 0}} type="submit" variant="contained" color="primary"
        loading={state === 'pending'}
        disabled={['pending'].includes(state) || !snapshot.exists() ||
                (fields.title ?? '').trim() === '' ||
                (fields.code ?? '').trim() === '' ||
                (fields.description ?? '').trim() === ''
        }
        autoFocus={true} onClick={submit}>
        <Trans i18nKey="course:forms.edit.submit"/>
      </Button>

      <Button sx={{flexShrink: 0}} type="submit" variant="outlined" color="primary"
        disabled={['pending'].includes(state) || !snapshot.exists() ||
                (fields.title ?? '').trim() === '' ||
                (fields.code ?? '').trim() === '' ||
                (fields.description ?? '').trim() === ''
        }
        onClick={cancel}>
        <Trans i18nKey="course:forms.edit.cancel"/>
      </Button>

    </Stack>
  </form>
}
