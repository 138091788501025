import type {Course} from '@goschool/model'
import {useUserCourseChats} from '@goschool/model'
import {GoSchool} from '@goschool/routing'
import ChatIcon from '@mui/icons-material/Chat'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import {Card, CardContent, CardHeader, Grid2 as Grid, Stack} from '@mui/material'
import type {QueryDocumentSnapshot} from 'firebase/firestore'
import {useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {CourseActionLink, CourseDescription, InstructorActionLink} from './components'
import {useUserContext} from '@goschool/auth'
import {CourseChatsCard} from './components/CourseChatsCard'

export function StudentCourse({snapshot}: { snapshot: QueryDocumentSnapshot<Course> }) {
  const [course, ref] = useMemo(
    () => [snapshot === null ? null : snapshot?.data(), snapshot?.ref],
    [snapshot]
  )

  const {user} = useUserContext()
  const {t} = useTranslation()

  const chats = useUserCourseChats(snapshot, user, 3)

  return <Stack direction="column" gap={4}>
    <Stack direction="row" gap={2} justifyContent="stretch">
      <Stack direction="row" gap={1} justifyContent="flex-start" flexGrow={1}>
        <InstructorActionLink variant="contained" label={t('course:actions.chat')}
          to={GoSchool.courseChat(snapshot.ref)}
          Icon={ChatIcon}></InstructorActionLink>
      </Stack>

      <Stack direction="row" gap={1} justifyContent="flex-end" flexGrow={1}>
        <CourseActionLink variant="outlined" label={t('course:actions.documents')}
          to={GoSchool.courseDocuments(ref)}
          Icon={MenuBookIcon}/>
      </Stack>
    </Stack>
    <Grid container spacing={2}>
      <Grid size={{xs: 12, md: 8}}>
        <Card sx={{height: '100%'}}>
          <CardHeader title={t('course:details.description')}/>
          <CardContent>
            <CourseDescription>{course?.description}</CourseDescription>
          </CardContent>
        </Card>
      </Grid>
      <Grid size={{xs: 12, md: 4}}>
        <CourseChatsCard
          sx={{height: '100%'}}
          chats={chats} title={t('course:details.recentChats')}/>
      </Grid>
    </Grid>
  </Stack>
}


