import type {PageReference} from '@goschool/components'
import {LoadingPage, PageLayout} from '@goschool/components'
import {TransRelTime} from '@goschool/i18n'
import type {Course, CourseChat, GoSchoolUser} from '@goschool/model'
import {useChatCreatedBy, useCourse, useCourseChats} from '@goschool/model'
import {NotFound, typeConverter, useFirestore} from '@goschool/react-firebase'

import {GoSchool} from '@goschool/routing'
import {Link, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material'
import type {DocumentSnapshot, QueryDocumentSnapshot} from 'firebase/firestore'
import {doc} from 'firebase/firestore'
import {useMemo} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {Link as RouterLink, useParams} from 'react-router-dom'
import {useCoursePageBreadcrumb} from '@goschool/courses'


export function useCourseChatsBreadcrumbs(courseSnapshot: DocumentSnapshot<Course> | null | undefined): PageReference[] {
  const {t} = useTranslation()
  const courseBreadcrumbs = useCoursePageBreadcrumb(courseSnapshot)

  if (!courseSnapshot?.exists()) {
    return []
  }

  return [
    ...courseBreadcrumbs,
    {
      title: t('course:chats.title'),
      href: GoSchool.courseChats(courseSnapshot.ref)
    }
  ]
}

export function CourseChatsPage() {
  const {organizationId, courseId} = useParams<{ organizationId: string, courseId: string }>()

  const courseSnapshot = useCourse(organizationId, courseId)
  const breadcrumbs = useCourseChatsBreadcrumbs(courseSnapshot)

  if (courseSnapshot == null) {
    return LoadingPage()
  }
  if (!courseSnapshot.exists()) {
    throw new NotFound()
  }

  return <PageLayout fullScreen={false} breadcrumbs={breadcrumbs}>
    <Typography variant="h4" component="h1" gutterBottom={true}><Trans i18nKey="course:chats.title"/></Typography>
    <CourseChatsTable courseSnapshot={courseSnapshot}/>
  </PageLayout>
}


export function CourseChatsTable({courseSnapshot, max}: {
  courseSnapshot: QueryDocumentSnapshot<Course>,
  max?: number
}) {
  const chats = useCourseChats(courseSnapshot, max)
  const {t} = useTranslation()

  return <TableContainer component={Paper} sx={{overflow: 'hidden', maxWidth: '100%'}}>
    <Table size="small" sx={{maxWidth: '100%', tableLayout: 'auto'}}>
      <TableHead>
        <TableRow>
          <TableCell>{t('course:chats.tableHeaders.title')}</TableCell>
          <TableCell>{t('course:chats.tableHeaders.user')}</TableCell>
          <TableCell>{t('course:chats.tableHeaders.lastMessage')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(chats ?? []).map((chat) => <ChatRow key={chat.id} snapshot={chat}/>)}
      </TableBody>
    </Table>
  </TableContainer>

}


function ChatRow({snapshot}: { snapshot: QueryDocumentSnapshot<CourseChat> }) {
  const chat = useMemo(() => snapshot.data(), [snapshot])
  const firestore = useFirestore()
  const userRef = useMemo(
    () =>
      chat.created_by != null && !(chat.anonymous ?? true)
        ? doc(firestore, 'users', chat.created_by).withConverter(typeConverter<GoSchoolUser>())
        : null,
    [chat, firestore]
  )
  const userSnapshot = useChatCreatedBy(chat)

  return <TableRow>
    <TableCell sx={{whiteSpace: 'nowrap', overflow: 'hidden', maxWidth: '400px', textOverflow: 'ellipsis'}}>
      <Link to={GoSchool.courseChatView(snapshot.ref)} component={RouterLink}>
        {chat.title ?? 'New Chat'}
      </Link>
    </TableCell>
    <TableCell>{userSnapshot?.data()?.name ?? 'Unknown'}</TableCell>
    <TableCell><TransRelTime ts={chat.updated_at}/> </TableCell>
  </TableRow>
}

