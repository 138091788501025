import type {ChatManager, ChatManagerState, MessageThread} from '../model'
import type {PropsWithChildren, ReactNode} from 'react'
import {createContext, useContext, useEffect, useMemo, useState} from 'react'
import type {MessageNode} from '../model/MessageTree'
import type {ConversationStarter, SubmitState} from '@goschool/model'
import {isAgentMessage} from '@goschool/model'


export type ContentComponentType =
  (props: { children: string | null | undefined }) => ReactNode

export interface ChatContextType {
  mode: 'chat' | 'view'
  ContentComponent?: ContentComponentType
  conversationStarters?: ConversationStarter[] | null
  chatManager: ChatManager
  thread: MessageThread | null | undefined
  generatingMessage: MessageNode | null
  lastMessage: MessageNode | null
  submitState: SubmitState
  managerState: ChatManagerState | undefined
}


export interface ChatContextProviderProps extends PropsWithChildren {
  mode: 'chat' | 'view'
  ContentComponent?: ContentComponentType
  conversationStarters?: ConversationStarter[] | null
  chatManager: ChatManager | undefined
}

const ChatContext = createContext<ChatContextType | undefined>(undefined)

export function ChatContextProvider({children, chatManager, ...context}: ChatContextProviderProps) {

  const [thread, setThread] = useState<MessageThread | null>()
  const [generatingMessage, setGeneratingMessage] = useState<MessageNode | null>(null)
  const [submitState, setSubmitState] = useState<SubmitState>('idle')
  const [managerState, setManagerState] = useState<ChatManagerState>()

  useEffect(() => {
    if (chatManager != null) {
      setThread(chatManager.thread)
      setSubmitState(chatManager.submitState)
      setGeneratingMessage(chatManager.generatingMessage)
      setManagerState(chatManager.state)

      const handleMessageStateUpdate = (node: MessageNode) => {
        const message = node.message.data()
        if (isAgentMessage(message)) {
          setGeneratingMessage((current) => {
            if (['generating', 'pending'].includes(message.status)) {
              return null
            }
            if (current != null) {
              console.warn('Overwriting generating message')
            }
            return node
          })
        }
      }
      const handleSubmitStateUpdate = () => {
        setSubmitState(chatManager.submitState)
      }
      const handleManagerStateUpdate = (state: ChatManagerState) => {
        setManagerState(state)
      }
      chatManager.addEventListener('threadUpdate', setThread)
      chatManager.addEventListener('messageStateUpdated', handleMessageStateUpdate)
      chatManager.addEventListener('submitStateUpdate', handleSubmitStateUpdate)
      chatManager.addEventListener('managerStateUpdate', handleManagerStateUpdate)

      return () => {
        chatManager.removeEventListener('threadUpdate', setThread)
        chatManager.removeEventListener('messageStateUpdated', handleMessageStateUpdate)
        chatManager.removeEventListener('submitStateUpdate', handleSubmitStateUpdate)
        chatManager.removeEventListener('managerStateUpdate', handleManagerStateUpdate)

        setThread(undefined)
        setGeneratingMessage(null)
        setSubmitState('idle')
      }
    }
  }, [chatManager])

  const lastMessage = useMemo(
    () => thread != null && thread.length > 0 ? thread[thread.length - 1] : null,
    [thread]
  )

  if (chatManager == null) {
    return null
  }

  return <ChatContext.Provider
    value={{
      ...context,
      chatManager,
      thread,
      generatingMessage,
      submitState,
      lastMessage,
      managerState
    }}>{children}</ChatContext.Provider>
}

export function useChatContext() {
  const context = useContext(ChatContext)
  if (context == null) {
    throw new Error('useChatContext must be used within a ChatContextProvider')
  }
  return context
}
