import {Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material'
import type {PageReference} from '@goschool/components'
import {PageLayout} from '@goschool/components'
import {Trans, useTranslation} from 'react-i18next'
import {useMemo} from 'react'

import {GoSchool} from '@goschool/routing'
import type {DocumentSnapshot, QueryDocumentSnapshot} from 'firebase/firestore'
import type {Course, CourseStudent} from '@goschool/model'
import {useCourse, useCourseStudents} from '@goschool/model'
import {NotFound} from '@goschool/react-firebase'
import {useParams} from 'react-router-dom'
import {useCoursePageBreadcrumb} from '../CoursePage'
import {useInstructorNames} from '@goschool/dao'
import {CoursePageHeader} from './components/CoursePageHeader'

function useStudentsPageBreadcrumbs(courseSnapshot: DocumentSnapshot<Course> | null | undefined): PageReference[] {
  const courseBreadcrumbs = useCoursePageBreadcrumb(courseSnapshot)
  const {t} = useTranslation()
  if (!courseSnapshot?.exists()) {
    return []
  }
  return [
    ...courseBreadcrumbs,
    {
      title: t('course:students.title'),
      href: GoSchool.courseStudents(courseSnapshot.ref)
    }
  ]
}

export function CourseStudentsPage() {
  const {organizationId, courseId} = useParams<{
    organizationId: string
    courseId: string
  }>()

  const courseSnapshot = useCourse(organizationId, courseId)

  const instructorNames = useInstructorNames(courseSnapshot)
  const breadcrumbs = useStudentsPageBreadcrumbs(courseSnapshot)
  if (courseSnapshot === undefined) {
    return null
  }

  if (!courseSnapshot?.exists()) {
    throw new NotFound()
  }


  return <PageLayout fullScreen={false} breadcrumbs={breadcrumbs}>
    <CoursePageHeader courseSnapshot={courseSnapshot}/>
    <Typography variant="h5" component="h3" gutterBottom={true}><Trans
      i18nKey="course:forms.edit.title"/></Typography>
    <CourseStudentsTable courseSnapshot={courseSnapshot}/>
  </PageLayout>
}


function CourseStudentsTable({courseSnapshot}: { courseSnapshot: QueryDocumentSnapshot<Course> }) {
  const students = useCourseStudents(courseSnapshot)
  const {t} = useTranslation()

  return <TableContainer component={Paper}>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>{t('course:students.tableHeaders.name')}</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(students ?? []).map((student) => <StudentRow key={student.id} snapshot={student}/>)}
      </TableBody>
    </Table>
  </TableContainer>
}


function StudentRow({snapshot}: { snapshot: QueryDocumentSnapshot<CourseStudent> }) {
  const student = useMemo(() => snapshot.data(), [snapshot])

  return <TableRow>
    <TableCell>{student.name}</TableCell>
  </TableRow>
}
