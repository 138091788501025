import {Authorize, useUserContext} from '@goschool/auth'
import {useCourseContext} from '@goschool/dao'
import {GoSchool} from '@goschool/routing'
import ChatIcon from '@mui/icons-material/Chat'
import ForumOutlinedIcon from '@mui/icons-material/ForumOutlined'
import GroupsIcon from '@mui/icons-material/Groups'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import QrCodeIcon from '@mui/icons-material/QrCode'
import ShareIcon from '@mui/icons-material/Share'
import type {IconButtonProps} from '@mui/material'
import {Card, CardContent, CardHeader, Grid2 as Grid, IconButton, Stack, Tooltip} from '@mui/material'
import type {QueryDocumentSnapshot} from 'firebase/firestore'
import {useCallback, useMemo} from 'react'
import {useTranslation} from 'react-i18next'
import {CourseDescription, InstructorActionLink} from './components'
import type {Course} from '@goschool/model'
import {useCourseChats, useUserCourseChats} from '@goschool/model'
import {LinkInvitationDialog, QRInvitationDialog, useCourseInvitationGenerator} from '../components/CourseInvitation'
import type {SvgIconComponent} from '@mui/icons-material'
import {CourseChatsCard} from './components/CourseChatsCard'
import EditIcon from '@mui/icons-material/Edit'
import AssistantIcon from '@mui/icons-material/Assistant'
import {useNavigate} from 'react-router-dom'

export function InstructorCourse({snapshot}: { snapshot: QueryDocumentSnapshot<Course> }) {
  const [course, ref] = useMemo(
    () => [snapshot === null ? null : snapshot?.data(), snapshot?.ref],
    [snapshot]
  )
  const {user} = useUserContext()

  const {t} = useTranslation()
  const studentChats = useCourseChats(snapshot, 3)
  const chats = useUserCourseChats(snapshot, user, 3)

  return <Stack direction="column" gap={4}>
    <Stack direction="row" gap={2} justifyContent="stretch">
      <Stack direction="row" gap={1} justifyContent="flex-start" flexGrow={1}>
        <InstructorActionLink variant="contained" label={t('course:actions.chat')}
          to={GoSchool.courseChat(snapshot.ref)}
          Icon={ChatIcon}></InstructorActionLink>
        <InstructorActionLink variant="outlined" label={t('course:actions.studentChats')}
          to={GoSchool.courseChats(ref)}
          Icon={ForumOutlinedIcon}/>
      </Stack>

      <Stack direction="row" gap={1} justifyContent="flex-end" flexGrow={1}>
        <InstructorActioIconLink label={t('course:actions.edit')}
          to={GoSchool.courseEdit(ref)}
          Icon={EditIcon}/>
        <InstructorActioIconLink label={t('course:actions.customizeAssistant')}
          to={GoSchool.courseCustomizeAssistant(ref)}
          Icon={AssistantIcon}/>

        <InstructorActioIconLink label={t('course:actions.documents')}
          to={GoSchool.courseDocuments(ref)}
          Icon={MenuBookIcon}/>
        <InstructorActioIconLink label={t('course:actions.students')}
          to={GoSchool.courseStudents(ref)}
          Icon={GroupsIcon}/>
        <InviteStudents/>
        <InviteQR/>
      </Stack>
    </Stack>
    <Grid container spacing={2} rowSpacing={2}>
      <Grid size={{xs: 12, md: 8}}>
        <Card sx={{height: '100%'}}>
          <CardHeader title={t('course:details.description')}/>
          <CardContent>
            <CourseDescription>{course?.description}</CourseDescription>
          </CardContent>
        </Card>
      </Grid>
      <Grid size={{xs: 12, md: 4}}>
        <Stack direction="column" gap={2}>
          <CourseChatsCard
            title={t('course:details.recentStudentChats')}
            chats={studentChats}/>
          <CourseChatsCard
            chats={chats} title={t('course:details.recentChats')}/>
        </Stack>
      </Grid>
    </Grid>
  </Stack>
}

export function InviteStudents() {
  const {invitation, isCreating, reset, createInvitation} = useCourseInvitationGenerator(24 * 7)
  const {t} = useTranslation()

  return <InstructorActionButton
    label={t('course:actions:inviteLink')} Icon={ShareIcon}
    onClick={createInvitation} disabled={isCreating}>
    <LinkInvitationDialog invitation={invitation} isCreating={isCreating} hide={reset}/>
  </InstructorActionButton>
}


export function InviteQR() {
  const {invitation, isCreating, reset, createInvitation} = useCourseInvitationGenerator(24 * 7)
  const {t} = useTranslation()

  return <InstructorActionButton
    label={t('course:actions:inviteQR')} Icon={QrCodeIcon}
    onClick={createInvitation}
    disabled={isCreating}>
    <QRInvitationDialog invitation={invitation} isCreating={isCreating} hide={reset}/>
  </InstructorActionButton>
}


interface InstructorActionButtonProps extends Omit<IconButtonProps, 'size' | 'color'> {
  Icon: SvgIconComponent;
  label: string
}

function InstructorActionButton({Icon, label, children, ...buttonProps}: InstructorActionButtonProps) {
  const {courseRoles} = useCourseContext()
  return <Authorize roles={['instructor', 'admin']} contextRoles={courseRoles}>
    <Tooltip title={label} arrow><IconButton color="primary"  {...buttonProps}>
      <Icon sx={{fontSize: '16px'}}/>
    </IconButton></Tooltip>
    {children}
  </Authorize>
}

interface InstructorActionLinkProps extends Omit<IconButtonProps, 'size' | 'color'> {
  Icon: SvgIconComponent;
  label: string
  to: string
}


function InstructorActioIconLink({Icon, label, children, to, ...buttonProps}: InstructorActionLinkProps) {
  const {courseRoles} = useCourseContext()
  const navigate = useNavigate()
  const handleClick = useCallback(
    () => navigate(to),
    [navigate, to]
  )
  return <Authorize roles={['instructor', 'admin']} contextRoles={courseRoles}>
    <Tooltip title={label} arrow><IconButton onClick={handleClick} color="primary"  {...buttonProps}>
      <Icon sx={{fontSize: '16px'}}/>
    </IconButton></Tooltip>
  </Authorize>
}
