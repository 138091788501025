import type {ChangeEvent, Dispatch, SetStateAction} from 'react'
import {useCallback, useEffect, useMemo, useState} from 'react'
import type {AdvancedConversationStarter, ConversationStarter, Course} from '@goschool/model'
import {isAdvancedConversationStarter} from '@goschool/model'
import {Button, Chip, Grid2 as Grid, IconButton, Stack, Switch, TextField, Typography} from '@mui/material'
import type {QueryDocumentSnapshot} from 'firebase/firestore'
import {arrayUnion, updateDoc} from 'firebase/firestore'

import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {Trans, useTranslation} from 'react-i18next'

interface ConversationStarterEditState<T extends ConversationStarter = ConversationStarter> {
  index: number | null
  starter: T
}

export function ConversationStarters({snapshot}: { snapshot: QueryDocumentSnapshot<Course> }) {
  const [error, setError] = useState<unknown | null>()
  const [editing, setEditing] = useState<ConversationStarterEditState | null>(null)


  const createNew = useCallback(() => {
    setEditing({index: null, starter: ''})
  }, [])


  const {t} = useTranslation()

  const remove = useCallback(
    (i: number) => {
      return async () => {
        if (window.confirm(t('course:customizeAssistant.confirmConversationStarterDeletion'))) {
          const starters = snapshot.data().conversation_starters
          if (starters == null || starters.length <= i || editing != null) {
            return
          }
          try {
            await updateDoc(snapshot.ref, {conversation_starters: starters.filter((_, j) => j !== i)})
          } catch (e) {
            setError(e)
          }
        }
      }
    }, [editing, snapshot, t]
  )

  const submit = useCallback(
    async (state: ConversationStarterEditState) => {
      if (state == null) {
        return
      }
      try {
        if (state.index == null) {
          await updateDoc(snapshot.ref, {
            conversation_starters: arrayUnion(state.starter)
          })
        } else {
          const starters = snapshot.data().conversation_starters
          if (starters == null || starters.length <= state.index) {
            return
          }
          starters[state.index] = state.starter
          await updateDoc(snapshot.ref, {conversation_starters: starters})
        }
      } catch (e) {
        setError(e)
      }

      setEditing(null)
    }, [snapshot])


  const cancel = useCallback(() => {
    setEditing(null)
  }, [])

  return <Grid container rowSpacing={2} spacing={2}>
    <Grid size={12}>
      <Stack direction="row" gap={2} flexWrap="wrap" alignItems="center">{
        (snapshot.data().conversation_starters ?? []).map(
          (starter, index) =>
            <ConversationStarterChip key={index} {...{starter, index, setEditing, remove: remove(index)}}/>)
      }
      <IconButton color="primary" onClick={createNew}><AddCircleIcon/></IconButton>
      </Stack>
    </Grid>
    <EditConversationStarter submit={submit} cancel={cancel} {...editing}/>
  </Grid>
}

interface ConversationStarterChipProps {
  starter: ConversationStarter
  index: number
  setEditing: (state: ConversationStarterEditState) => void
  remove: (index: number) => void
}

function ConversationStarterChip({starter, remove, index, setEditing}: ConversationStarterChipProps) {
  const [title] = useMemo(
    () => typeof starter === 'string'
      ? [starter, starter]
      : [starter.title, starter.message],
    [starter]
  )

  return <Chip
    variant="outlined" icon={<TipsAndUpdatesIcon fontSize="small" color="info"/>} label={title}
    onClick={() => setEditing({index, starter})}
    onDelete={remove}
  />
}

interface EditConversationStarterProps<T extends ConversationStarter = ConversationStarter> {
  starter?: T
  index?: number | null
  submit: (state: ConversationStarterEditState) => void
  cancel: () => void
}

function AdvancedSwitch({starter, setStarter}: {
  starter: ConversationStarter,
  setStarter: Dispatch<SetStateAction<ConversationStarter | undefined>>
}) {
  const isAdvanced = useMemo(
    () => isAdvancedConversationStarter(starter),
    [starter]
  )

  const setAdvanced = useCallback(
    (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {

      setStarter(
        (current) => {
          if (current == null) {
            if (checked) {
              return {title: '', message: ''}
            } else {
              return ''
            }
          }
          if (checked && !isAdvancedConversationStarter(current)) {
            return {title: current, message: ''}
          } else if (!checked && isAdvancedConversationStarter(current)) {
            return current.message
          }
          return current
        }
      )
    },
    [setStarter]
  )

  return <Stack direction="row" spacing={2} alignItems="center">
    <Stack direction="row" spacing={0} alignItems="center">
      <Typography variant="caption" textAlign="right"><Trans
        i18nKey="course:customizeAssistant.simpleConversationStarter"/></Typography>
      <Switch checked={isAdvanced} onChange={setAdvanced}/>
      <Typography variant="caption" textAlign="left"><Trans
        i18nKey="course:customizeAssistant.advancedConversationStarter"/></Typography>
    </Stack>
  </Stack>
}

function EditConversationStarter({starter, index, submit, cancel}: EditConversationStarterProps) {
  const [value, setValue] = useState<ConversationStarter>()

  useEffect(() => {
    setValue(starter)
    return () => setValue(undefined)
  }, [starter])


  if (value === undefined || index === undefined) {
    return null
  }

  return <>
    <Grid size={10}>{
      isAdvancedConversationStarter(value)
        ? <AdvancedConversationStarterForm {...{starter: value, update: setValue}}/>
        : <SimpleConversationStarterForm {...{starter: value, update: setValue}}/>
    }</Grid>
    <Grid size={2}>
      <AdvancedSwitch starter={value} setStarter={setValue}/>
    </Grid>
    <Grid size={10}><Stack direction="row" gap={2} justifyContent="flex-end">
      <Button variant="contained" onClick={() => submit({index, starter: value})}><Trans i18nKey="course:forms.submit"/></Button>
      <Button variant="outlined" onClick={cancel}><Trans i18nKey="course:forms.cancel"/></Button>
    </Stack></Grid>
  </>
}

function SimpleConversationStarterForm({starter, update}: { starter: string, update: (starter: string) => void }) {
  const {t} = useTranslation()
  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      update(event.target.value)
    }, [update])

  return <TextField
    label={t('course:customizeAssistant.simpleStarterLabel')} size="small" fullWidth value={starter}
    placeholder={t('course:customizeAssistant.simpleStarterPlaceholder')}
    onChange={handleChange}/>

}

function AdvancedConversationStarterForm(
  {starter, update}: { starter: AdvancedConversationStarter, update: (starter: AdvancedConversationStarter) => void }) {
  const {t} = useTranslation()

  const handleTitleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      update({...starter, title: event.target.value})
    }, [starter, update])

  const handleMessageChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      update({...starter, message: event.target.value})
    }, [starter, update])

  return <Stack direction="column" gap={2}>
    <TextField
      size="small" fullWidth value={starter.title}
      placeholder={t('course:customizeAssistant.advancedStarterTitlePlaceholder')}
      label={t('course:customizeAssistant.advancedStarterTitleLabel')}

      onChange={handleTitleChange}
    />
    <TextField
      size="small" multiline minRows={3} fullWidth value={starter.message}
      placeholder={t('course:customizeAssistant.advancedStarterMessagePlaceholder')}
      label={t('course:customizeAssistant.advancedStarterMessageLabel')}

      onChange={handleMessageChange}/>
  </Stack>
}
