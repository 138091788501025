import {LoadingPage, PageLayout} from '@goschool/components'
import {useParams} from 'react-router-dom'
import {NotFound} from '@goschool/react-firebase'
import {CourseContextProvider, useInstructorNames} from '@goschool/dao'
import {useMemo} from 'react'
import {GoSchool} from '@goschool/routing'
import type {Course} from '@goschool/model'
import {useCourse} from '@goschool/model'
import {CourseDocuments} from '../documents'
import {useTranslation} from 'react-i18next'
import {useCoursePageBreadcrumb} from '../CoursePage'
import type {DocumentSnapshot} from 'firebase/firestore'
import {CoursePageHeader} from './components/CoursePageHeader'


export function useCourseDocumentsPageBreadcrumb(courseSnapshot: DocumentSnapshot<Course> | null | undefined) {
  const {t} = useTranslation()

  const courseBreadcrumbs = useCoursePageBreadcrumb(courseSnapshot)
  if (!courseSnapshot?.exists()) {
    return []
  }
  return [
    ...courseBreadcrumbs,
    {
      title: t('course:actions.documents'),
      href: GoSchool.courseDocuments(courseSnapshot.ref)
    }
  ]
}

export function CourseDocumentsPage() {
  const {organizationId, courseId} = useParams<{
    organizationId: string
    courseId: string
  }>()

  const courseSnapshot = useCourse(organizationId, courseId)
  const course = useMemo(
    () => courseSnapshot === null ? null : courseSnapshot?.data(),
    [courseSnapshot]
  )

  const instructorNames = useInstructorNames(courseSnapshot)
  const breadcrumbs = useCourseDocumentsPageBreadcrumb(courseSnapshot)
  if (courseSnapshot == null) {
    return LoadingPage()
  }

  if (!courseSnapshot.exists()) {
    throw new NotFound()
  }


  if (course === null) {
    throw new NotFound()
  }

  return <PageLayout fullScreen={false} breadcrumbs={breadcrumbs}>
    <CoursePageHeader courseSnapshot={courseSnapshot}/>

    <CourseContextProvider courseSnapshot={courseSnapshot}>
      <CourseDocuments/>
    </CourseContextProvider>
  </PageLayout>
}
