import type {AgentMessage} from '@goschool/model'
import {getToolResults} from '@goschool/model'
import React, {useCallback, useMemo} from 'react'
import type { ChipProps} from '@mui/material'
import {Chip, Stack, styled} from '@mui/material'
import type OpenAI from 'openai'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SearchIcon from '@mui/icons-material/Search'
import ManageSearchIcon from '@mui/icons-material/ManageSearch'
import {groupBy} from 'lodash'
import MenuBookIcon from '@mui/icons-material/MenuBook'
import FolderOpenIcon from '@mui/icons-material/FolderOpen'
import {useTranslation} from 'react-i18next'
import {useChatContext} from './ChatContext'

export function ToolUsage({message}: { message: AgentMessage }) {
  const toolResults = useMemo(
    () => getToolResults(message),
    [message]
  )

  const getFunctionCalls = useCallback(
    (functionName: string) => toolResults
      .filter(([call]) => call.function.name === functionName),
    [toolResults]
  )

  return <ToolUsageStack direction="row">
    <>{
      getFunctionCalls('course_info')
        .map(
          ([call, result], i) =>
            <CourseInfo key={i}/>
        )
    }</>
    <>{
      getFunctionCalls('course_documents')
        .map(
          ([call, result], i) =>
            <CourseDocuments key={i}/>
        )
    } </>

    <>{
      getFunctionCalls('search')
        .map(
          ([call, result], i) =>
            <Search key={i} call={call} result={result}/>
        )
    } </>
  </ToolUsageStack>
}

const ToolUsageStack = styled(Stack, {name: 'ToolUsageStack'})(({theme}) => ({
  paddingTop: theme.spacing(1),
  //paddingLeft: theme.spacing(4),
  overflow: 'hidden',
  flexWrap: 'wrap',
  gap: theme.spacing(1)
}))

function CourseInfo() {
  const {t} = useTranslation()
  const {mode} = useChatContext()
  if (mode === 'view') {
    return <ToolChip icon={<InfoOutlinedIcon/>} label={t('chat:message.tools.course-info')}/>
  }
}

function CourseDocuments() {
  const {t} = useTranslation()
  const {mode} = useChatContext()
  if (mode === 'view') {
    return <ToolChip icon={<FolderOpenIcon/>} label={t('chat:message.tools.course-docs')}/>
  }
}

interface DocumentQuery {
  query: string
  language_code?: string
  document_id?: string
  top_k?: string
}

interface RetrievalSearchParamsV0 {
  queries: DocumentQuery[]
}

interface RetrievalSearchParams {
  queries: string[]
  language_code: string
  top_k?: number
  document_id?: string
}

function isDocumentQuery(q: unknown): q is DocumentQuery {
  return q instanceof Object && 'query' in q && typeof q.query === 'string'
}

function isRetrievalSearchParamsV0(params: unknown): params is RetrievalSearchParamsV0 {
  return params instanceof Object && 'queries' in params && Array.isArray(params.queries) && params.queries.every(
    isDocumentQuery
  )
}

function isRetrievalSearchParams(params: unknown): params is RetrievalSearchParams {
  return params instanceof Object && 'queries' in params && Array.isArray(params.queries) && params.queries.every(
    (q: unknown) => typeof q === 'string'
  )
}

function Search({call, result}: {
  call: OpenAI.ChatCompletionMessageToolCall,
  result: OpenAI.ChatCompletionToolMessageParam | undefined
}) {

  const functionArguments = useMemo(
    () => JSON.parse(call.function.arguments),
    [call.function.arguments]
  )

  const {t} = useTranslation()
  if (result == null) {
    return <ToolChip icon={result == null ? <SearchIcon/> : <ManageSearchIcon/>}
      label={t('chat:message.tools.search')}/>
  }

  if (isRetrievalSearchParamsV0(functionArguments)) {
    return <SearchResultsV0 call={call} toolMessage={result} functionArguments={functionArguments}/>
  } else if (isRetrievalSearchParams(functionArguments)) {
    return <SearchResults call={call} toolMessage={result} functionArguments={functionArguments}/>
  }

}


function SearchResults({toolMessage}: {
  call: OpenAI.ChatCompletionMessageToolCall | undefined,
  toolMessage: OpenAI.ChatCompletionToolMessageParam,
  functionArguments: RetrievalSearchParams
}) {
  const [, hits] = useMemo(
    () => {
      const content = Array.isArray(toolMessage.content)
        ? toolMessage.content.map(
          (m) => m.text
        ).join('\n')
        : toolMessage.content
      const result = JSON.parse(content)
      const hits = result.hits as RetrievalHit[]
      const queries = result.queries as DocumentQuery[]
      return [queries, hits]
    }, [toolMessage]
  )

  const documents = useMemo(
    () => Object.values(groupBy(hits.filter(h => h.reference?.title != null), h => h.reference?.title)).map(
      ([first]) => first?.reference?.title
    ).filter((t: string | undefined) => t != null),
    [hits]
  )

  return <>{
    documents.map(
      (d, i) => <ToolChip key={i} icon={<MenuBookIcon/>} label={d}/>
    )
  }</>
}

function SearchResultsV0({toolMessage}: {
  call: OpenAI.ChatCompletionMessageToolCall,
  toolMessage: OpenAI.ChatCompletionToolMessageParam,
  functionArguments: RetrievalSearchParamsV0
}) {
  return <ToolChip icon={toolMessage == null ? <SearchIcon/> : <ManageSearchIcon/>} label="search"/>
}

function ToolChip(props: ChipProps) {
  return <StyledToolChip size="small" variant="outlined" color="info" {...props} />
}

const StyledToolChip = styled(Chip, {name: 'StyledToolChip'})(({theme}) => ({
  paddingLeft: theme.spacing(0.5),
  paddingRight: theme.spacing(0.5),
  '& .MuiChip-icon': {
    width: '1rem',
    height: '1rem'
  },
  '& .MuiChip-label': {
    ...theme.typography.caption
  }
}))


interface RetrievalHit {
  score: number;
  content: string;
  reference?: {
    title: string
    authors: string[]
    page?: number
    start_offset?: number
  };
}

