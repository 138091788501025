import {Authorize} from '@goschool/auth'
import {type PropsWithChildren, type ReactNode, useMemo, useState} from 'react'
import {Trans, useTranslation} from 'react-i18next'
import {FixedFab, LoadingPage, PageLayout} from '@goschool/components'
import {
  Fab,
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import {typeConverter, useFirestore, useFirestoreSnapshot} from '@goschool/react-firebase'
import type {QueryDocumentSnapshot} from 'firebase/firestore'
import {doc} from 'firebase/firestore'
import type {GoSchoolInvitation, GoSchoolUser} from '@goschool/model'
import {useAccepted, useInvitations} from '@goschool/model'
import {TransRelTime} from '@goschool/i18n'
import {CreateInvitationDialog} from './components/InvitationDialog'
import {GoSchool} from '@goschool/routing'
import {OrganizationSelector, useSelectedOrganization} from '../components/OrganizationSelector'

function invitationsBreadcrumbs() {
  return [
    {title: 'Admin', href: GoSchool.admin},
    {
      title: 'Meghívók',
      href: '/admin/invitations'
    }
  ]
}

export function InvitationsPage() {

  const organization = useSelectedOrganization()
  const invitations = useInvitations(organization)
  const [creating, setCreating] = useState(false)

  if (invitations == null) {
    return <LoadingPage/>
  }


  return <PageLayout fullScreen={false} breadcrumbs={invitationsBreadcrumbs()}>
    <Typography variant="h4" component="h1" gutterBottom={true}><Trans i18nKey="auth:invitations.title"/></Typography>
    <Stack direction="column" gap={4}>
      <Stack direction="row" gap={2}>
        <OrganizationSelector/>
      </Stack>
      <Invitations invitations={invitations} setCreating={setCreating}/>
      <CreateInvitationDialog displayed={creating} hide={() => setCreating(false)}/>

    </Stack>

  </PageLayout>
}


function Invitations({invitations, setCreating}: {
  invitations: QueryDocumentSnapshot<GoSchoolInvitation>[],
  setCreating: (creating: boolean) => void
}) {
  const {t} = useTranslation()

  if ((invitations ?? []).length === 0) {
    return <>
      <Trans i18nKey="auth:invitations.warnings.noInvitations.description"/>
      <Fab color="primary" sx={{marginLeft: '1rem'}}
        onClick={() => setCreating(true)}><AddIcon/></Fab>
    </>
  }


  return <>
    <TableContainer component={Paper}>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>{t('auth:invitations.tableHeaders.issuer')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.name')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.roles')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.email')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.course')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.accepted')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.created_at')}</TableCell>
            <TableCell>{t('auth:invitations.tableHeaders.expires_at')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(invitations ?? []).map((invitation) => <InvitationRow key={invitation.id}
            snapshot={invitation}/>)}
        </TableBody>
      </Table>
    </TableContainer>
    <Authorize roles={['admin']}>
      <FixedFab onClick={() => setCreating(true)} color="primary" horizontal="right" vertical="bottom">
        <AddIcon/>
      </FixedFab>
    </Authorize>
  </>
}


function InvitationRow({snapshot}: { snapshot: QueryDocumentSnapshot<GoSchoolInvitation> }) {
  const invitation = useMemo(() => snapshot.data(), [snapshot])
  const courseRef = useMemo(
    () => invitation.course,
    [invitation]
  )
  const course = useFirestoreSnapshot(courseRef)
  useFirestoreSnapshot(useMemo(
    () => invitation.organization,
    [invitation]
  ), {watch: false})
  const firestore = useFirestore()
  const issuedBy = useFirestoreSnapshot(useMemo(
    () => doc(firestore, 'users', invitation.created_by)
      .withConverter(typeConverter<GoSchoolUser>()),
    [firestore, invitation]
  ), {watch: false})
  const accepted = useAccepted(snapshot)

  return <TableRow>
    <TableCell sx={{whiteSpace: 'nowrap'}}>{issuedBy?.data()?.name}</TableCell>
    <TableCell sx={{whiteSpace: 'nowrap'}}><Link
      href={`${window.location.origin}${GoSchool.invitation(snapshot.ref)}`}>{invitation.name}</Link></TableCell>
    <TableCell>{invitation.roles.join(', ')}</TableCell>
    <TableCell>{invitation.email}</TableCell>
    <TableCell title={course?.data()?.title}
      sx={{
        whiteSpace: 'nowrap',
        maxWidth: '150px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}>{course?.data()?.title}</TableCell>
    <TableCell>{invitation.name != null
      ? (accepted?.length ?? 0) > 0 ? '✓' : null
      : accepted?.length ?? null}</TableCell>
    <TableCell><TransRelTime ts={invitation.created_at}/></TableCell>
    <TableCell><TransRelTime ts={invitation.expires_at}/></TableCell>
  </TableRow>

}


function WarningPage({icon, title, children}: PropsWithChildren<{ icon: ReactNode, title: string }>) {
  return <PageLayout fullScreen={true} centered={true}>
    <Stack direction="row" gap={2} alignItems="center" justifyContent="stretch">
      {/*<WarningAvatar>{icon}</WarningAvatar>*/}
      <Typography variant="h3" component="h1">
        {title}
      </Typography>
    </Stack>
    <Typography variant="h5" component="p">{children}</Typography>
  </PageLayout>
}
