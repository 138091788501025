import type {AgentMessage, UserMessage} from '@goschool/model'
import {isAgentMessage, isUserMessage} from '@goschool/model'
import type {PropsWithChildren} from 'react'
import React, {useLayoutEffect, useMemo, useRef} from 'react'
import {Alert, Box, styled} from '@mui/material'


import {useChatViewContext} from './ChatViewContext'
import {MessageMarkdownComponent} from './MarkdownMessageComponent'
import {useChatContext} from './ChatContext'
import {ToolUsage} from './ToolUsage'
import {AgentMessageActions} from './MessagActions'
import {useMessageNodeContext} from './MessageNodeContext'


export function ChatMessageView() {
  const {node} = useMessageNodeContext()
  const {message: snapshot} = node
  const message = useMemo(
    () => snapshot.data(),
    [snapshot]
  )
  if (message === undefined) {
    throw new Error('Message data is undefined')
  }
  if (isUserMessage(message)) {
    return <UserMessageView message={message}/>
  } else if (isAgentMessage(message)) {
    return <AgentMessageView message={message}/>
  } else {
    throw new Error('Unknown message type')
  }
}


function UserMessageView({message}: { message: UserMessage }) {
  const {chatManager} = useChatContext()

  if (chatManager === undefined) {
    throw new Error('Chat is undefined')
  }

  return <MessageContainer>
    <MessageContentContainer>
      <UserMessageContentBox><MessageContent>{message.content}</MessageContent></UserMessageContentBox>
    </MessageContentContainer>
  </MessageContainer>
}

const UserMessageContentBox = styled(Box, {
  name: 'UserMessageContentBox',
  slot: 'Root'
})(({theme}) => ({
  marginTop: theme.spacing(1),
  backgroundColor: theme.palette.primary.main,//theme.palette.grey[800],
  color: theme.palette.primary.contrastText, //getContrastText(theme.palette.grey[800]),
  padding: theme.spacing(2),
  paddingTop: theme.spacing(0.5),
  paddingBottom: theme.spacing(0.5),
  borderRadius: theme.spacing(1),
  width: 'fit-content',
  marginLeft: 'auto',
  marginRight: 0
}))

function MessageContainer({children}: PropsWithChildren) {
  const {generatingMessage, lastMessage} = useChatContext()
  const {scrollIntoView} = useChatViewContext()
  const {node} = useMessageNodeContext()

  const messageContainerRef = useRef<HTMLDivElement>(null)

  useLayoutEffect(() => {
    if (messageContainerRef.current != null) {
      const currentNode = messageContainerRef.current
      const generatingId = generatingMessage?.message.id
      const lastId = lastMessage?.message.id
      const nodeId = node.message.id
      if (generatingId != null) {
        if (generatingId === nodeId) {
          scrollIntoView(currentNode)
        } else if (lastId === nodeId) {
          scrollIntoView(currentNode)
        }
      }
    }
  }, [generatingMessage?.message.id, lastMessage?.message.id, node.message.id, scrollIntoView])

  return <MessageContainerBox key={node.message.id} ref={messageContainerRef}>{children}</MessageContainerBox>
}

function AgentMessageView({message}: { message: AgentMessage }) {

  return <MessageContainer>
    <ToolUsage message={message}/>
    <MessageContentContainer>
      <AIMessageContent message={message}/>
    </MessageContentContainer>
    <AgentMessageActions message={message}/>
  </MessageContainer>
}


function AIMessageContent({message}: { message: AgentMessage }) {
  switch (message.status) {
  case 'pending':
    return <Pending/>
  case 'generating':
    if ((message.content ?? '').trim() === '') {
      return <Pending/>
    }
    return <MessageContent>{message.content}</MessageContent>
  case 'completed':
    return <MessageContent>{message.content}</MessageContent>
  case 'failed':
    return (
      <Alert severity="error">
          An error occurred while generating this message. Please try again.
      </Alert>
    )
  }
}

function MessageContent({children}: { children: string | null | undefined }) {
  const {ContentComponent} = useChatContext()

  if (ContentComponent === undefined) {
    return <MessageMarkdownComponent>{children}</MessageMarkdownComponent>
  }
  return <ContentComponent>{children}</ContentComponent>
}

const MessageContainerBox = styled(Box, {
  name: 'MessageContainer',
  slot: 'Root'
})(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  '&:not(:hover)': {
    '& .MessageActionButton .MuiSvgIcon-root': {
      color: theme.palette.divider
    },
    '& .MessageActions': {
      visibility: 'hidden'
    }
  }
}))

const MessageContentContainer = styled(Box, {
  name: 'MessageContentContainer',
  slot: 'Root'
})(({theme}) => ({
  //paddingLeft: theme.spacing(4)
}))


function Pending() {
  const {duration, begin} = useMemo(() => {
    const dur = 1
    return {
      duration: `${dur}s`,
      begin: [
        `0;dot2.end-${dur / 3}s`,
        `dot0.end-${dur * 0.8}s`,
        `dot0.end-${dur * 0.6}s`
      ]
    }
  }, [])

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2rem"
      height="2rem"
      viewBox="0 0 24 24"
    >
      <g fill="currentColor">
        <circle cx={4} cy={12} r={3}>
          <animate
            id="dot0"
            dur={duration}
            attributeName="r"
            values="3;.2;3"
            begin={begin[0]}
          />
        </circle>
        <circle cx={12} cy={12} r={3}>
          <animate
            dur={duration}
            attributeName="r"
            values="3;.2;3"
            begin={begin[1]}
          />
        </circle>
        <circle cx={20} cy={12} r={3}>
          <animate
            id="dot2"
            dur={duration}
            attributeName="r"
            values="3;.2;3"
            begin={begin[2]}
          />
        </circle>
      </g>
    </svg>
  )
}
