import {Authorize} from '@goschool/auth'
import {useCourseContext} from '@goschool/dao'
import type {GoSchoolUserRole} from '@goschool/model'
import type {SvgIconComponent} from '@mui/icons-material'
import type {ButtonProps} from '@mui/material'
import {Button, IconButton, useMediaQuery, useTheme} from '@mui/material'
import {Link as RouterLink} from 'react-router-dom'

type InstructorActionLinkProps = Omit<CourseActionLinkProps, 'roles'>

export function InstructorActionLink(props: InstructorActionLinkProps) {
  return <CourseActionLink {...props} roles={['instructor']}/>
}

interface CourseActionLinkProps extends Omit<ButtonProps, 'size' | 'color'> {
  roles?: GoSchoolUserRole[]
  to: string,
  Icon: SvgIconComponent
  label: string
}

export function CourseActionLink(props: CourseActionLinkProps) {
  const {
    to, Icon, label,
    roles = ['instructor', 'student', 'admin'],
    ...buttonProps
  } = props

  const theme = useTheme()
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('sm'))
  const {courseRoles} = useCourseContext()
  if (isLargeScreen) {
    return <Authorize roles={roles} contextRoles={courseRoles}>
      <Button color="primary" size="small" startIcon={<Icon sx={{fontSize: '32px'}}/>} component={RouterLink}
        to={to} {...buttonProps}>
        {label}
      </Button>
    </Authorize>
  } else {
    return <Authorize roles={roles} contextRoles={courseRoles}>
      <IconButton color="primary" size="small" title={label} component={RouterLink} to={to} {...buttonProps}>
        <Icon sx={{fontSize: '32px'}}/>
      </IconButton>
    </Authorize>
  }
}
