import {Stack, Typography} from '@mui/material'
import {useCustomGoSchool} from '@goschool/components'


export function NotFound() {
  const {secondaryLogo} = useCustomGoSchool()
  return <Stack gap={2} justifyContent="center" alignItems="center" width="100vw" height="100vh">
    <img src={secondaryLogo} alt="GoSchool.AI" height={200}/>

    <Typography variant="h2">Not Found</Typography>
  </Stack>
}


