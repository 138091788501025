import {Typography} from '@mui/material'
import {PageLayout} from '@goschool/components'
import {useParams} from 'react-router-dom'

import type {QueryDocumentSnapshot} from 'firebase/firestore'
import type {Course, CourseChat} from '@goschool/model'
import {useChat, useCourse} from '@goschool/model'
import {useUserContext} from '@goschool/auth'

import {ChatContextProvider, ChatMessages} from '@goschool/shared/chat'
import {NotFound} from '@goschool/react-firebase'
import {GoSchool} from '@goschool/routing'
import {useChatManager} from './components/useChatManager'
import {useMemo} from 'react'
import {useCourseChatsBreadcrumbs} from './CourseChatsPage'


export function CourseChatViewPage() {
  const {organizationId, courseId, chatId} = useParams<{
    organizationId: string
    courseId: string
    chatId: string
  }>()

  const courseSnapshot = useCourse(organizationId, courseId)
  const courseChatSnapshot = useChat(organizationId, courseId, chatId)

  if (courseSnapshot === undefined || courseChatSnapshot === undefined) {
    return null
  }

  if (!courseSnapshot?.exists() || !courseChatSnapshot?.exists()) {
    throw new NotFound()
  }


  return <CourseChatViewLayout courseSnapshot={courseSnapshot} courseChatSnapshot={courseChatSnapshot}/>
}

function useCourseChatViewBreadcrumbs(
  courseSnapshot: QueryDocumentSnapshot<Course>,
  selectedChat: QueryDocumentSnapshot<CourseChat>) {
  const chatBreadcrumbs = useCourseChatsBreadcrumbs(courseSnapshot)
  return [
    ...chatBreadcrumbs,
    {
      title: selectedChat.data().title ?? 'New Chat',
      href: GoSchool.courseChatView(selectedChat.ref)
    }
  ]
}

function CourseChatViewLayout({courseSnapshot, courseChatSnapshot}: {
  courseSnapshot: QueryDocumentSnapshot<Course>,
  courseChatSnapshot: QueryDocumentSnapshot<CourseChat>
}) {
  const {user} = useUserContext()
  const chatRef = useMemo(() => courseChatSnapshot.ref, [courseChatSnapshot])

  const chatManager = useChatManager(user, chatRef)

  return <ChatContextProvider chatManager={chatManager} mode="view">
    <PageLayout
      fullScreen={false}
      breadcrumbs={useCourseChatViewBreadcrumbs(courseSnapshot, courseChatSnapshot)}>
      <Typography variant="h4" component="h1"
        gutterBottom={true}>{courseChatSnapshot.data().title ?? 'New Chat'}</Typography>
      <ChatMessages/>
    </PageLayout>
  </ChatContextProvider>
}
