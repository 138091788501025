import type {Timestamp} from 'firebase/firestore'
import {Trans} from 'react-i18next'

export function TransDate({ts, defaultValue = ''}: { ts: Timestamp | undefined | null, defaultValue?: string }) {
  if (ts == null) {
    return defaultValue
  }

  return <Trans i18nKey="date" values={{timestamp: ts.toDate()}}/>
}

export function TransTime({ts, defaultValue = ''}: { ts: Timestamp | undefined | null, defaultValue?: string }) {
  if (ts == null) {
    return defaultValue
  }

  return <Trans i18nKey="time" values={{timestamp: ts.toDate()}}/>
}

export function TransRelTime({ts, defaultValue = ''}: { ts: Timestamp | undefined | null, defaultValue?: string }) {
  if (ts == null) {
    return defaultValue
  }

  const diffInMillis = ts.toDate().getTime() - Date.now()
  const diffInSeconds = -Math.round(diffInMillis / 1000)
  const diffInMinutes = Math.round(diffInSeconds / 60)
  const diffInHours = Math.round(diffInMinutes / 60)
  const diffInDays = Math.round(diffInHours / 24)

  let key: 'relativeTime.daysPast' | 'relativeTime.hoursPast' | 'relativeTime.minutesPast'
      | 'relativeTime.daysAhead' | 'relativeTime.hoursAhead' | 'relativeTime.minutesAhead' =
      diffInMillis < 0 ? 'relativeTime.daysPast' : 'relativeTime.daysAhead'
  let value = {diff: diffInDays}

  if (Math.abs(diffInHours) < 24) {
    key = diffInMillis < 0 ? 'relativeTime.hoursPast' : 'relativeTime.hoursAhead'
    value = {diff: diffInHours}
  }
  if (Math.abs(diffInMinutes) < 60) {
    key = diffInMillis < 0 ? 'relativeTime.minutesPast' : 'relativeTime.minutesAhead'
    value = {diff: diffInMinutes}
  }
  if (diffInMillis >= 0) {
    value.diff = -value.diff
  }

  return <Trans i18nKey={key} values={value}/>
}
